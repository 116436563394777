import StoreHelper from "../helper/store-helper";

export default {
  namespaced: true,
  state() {
    return {
      options: null,
      loading: false,
      errors: false,
    };
  },
  mutations: {
    setOptions(state, payload) {
      state.options = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    async loadOptions(context) {
      context.commit("setLoading", true);
      /**
       * cache options
       * in localStorage to reduce
       * requests
       */
      let responseData = JSON.parse(localStorage.getItem("attributeOptions"));
      const storageDataVersion = JSON.parse(localStorage.getItem("version"));
      if (!responseData || process.env.VUE_APP_VERSION !== storageDataVersion) {
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}attribute_options/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
          }
        );

        responseData = await response.json();
        localStorage.setItem("attributeOptions", JSON.stringify(responseData));
        localStorage.setItem(
          "version",
          JSON.stringify(process.env.VUE_APP_VERSION)
        );
        StoreHelper.handleHttpError(response, context);
      }
      context.commit("setOptions", responseData);
      context.commit("setLoading", false);
    },
  },
  getters: {
    options(state) {
      return state.options;
    },
    errors(state) {
      return state.errors;
    },
    isLoading(state) {
      return state.loading;
    },
    getDropdownOption(state) {
      return (name) => {
        let options = [];
        if (state.options) {
          for (const [key, value] of Object.entries(state.options[name])) {
            options.push({ value: key, label: value });
          }
        }
        return options;
      };
    },
  },
};
