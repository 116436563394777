<template>
  <div style="display: none; visibility: hidden; width: 0; height: 0">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <symbol id="account" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 01-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 01-6 3.22z"
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="cat-logo" viewBox="0 0 102 155">
        <svg width="102" height="155" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path
              d="M68.5 154C50 154 35 138.937 35 120.356V83.827l9.812 9.854a33.322 33.322 0 0023.688 9.854 33.322 33.322 0 0023.688-9.854c9.874-9.916 12.295-24.484 7.264-36.681A33.673 33.673 0 01102 69.892v50.464C102 138.937 87.001 154 68.5 154"
              class="colorize-fill"
              fill="currentColor"
              opacity=".45"
            />
            <path
              d="M68.5 103c-8.574 0-17.147-3.27-23.688-9.811L35 83.376V69.5C35 51 49.999 36 68.5 36c13.955 0 25.916 8.532 30.952 20.664 5.032 12.145 2.61 26.65-7.264 36.525-6.54 6.54-15.114 9.811-23.687 9.811"
              class="colorize-fill"
              fill="#EAAB00"
            />
            <path
              d="M9.814 58.252c-13.085-13.097-13.085-34.332 0-47.429C16.356 4.273 24.93 1 33.506 1c8.573 0 17.15 3.275 23.69 9.823l35.402 35.435c-6.052-5.989-14.372-9.687-23.555-9.687-18.504 0-33.505 15.016-33.505 33.538V84c-9.113-9.12-20.833-20.853-25.724-25.748zM100 57.258a33.577 33.577 0 00-6.34-9.9 33.357 33.357 0 016.34 9.9m-6.34-9.9c-.3-.326-.61-.647-.925-.964l-.137-.136c.362.358.717.726 1.063 1.1"
              class="colorize-fill"
              fill="currentColor"
              opacity=".7"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="exclamation-mark" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"
              fill="#FAFAFA"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="arrow-down" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
              d="M7 10l5 5 5-5z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="back" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
              d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="check" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
              d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="link" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.71-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="minus" viewBox="0 0 28 32">
        <svg width="28" height="32" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path
              d="M4 0h24v32H4a4 4 0 01-4-4V4a4 4 0 014-4z"
              class="colorize-background"
              style="fill: var(--color_background)"
            />
            <path d="M4 6h20v20H4z" />
            <path
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
              d="M19.833 16.833H8.167v-1.666h11.666z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="play-circle" viewBox="0 0 44 44">
        <svg width="44" height="44" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h44v44H0z" />
            <path
              d="M22.5 4C12.288 4 4 12.288 4 22.5S12.288 41 22.5 41 41 32.712 41 22.5 32.712 4 22.5 4zm-3.7 26.825v-16.65L29.9 22.5l-11.1 8.325z"
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="play" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
              d="M8 5v14l11-7z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="plus" viewBox="0 0 28 32">
        <svg width="28" height="32" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path
              d="M0 0h24a4 4 0 014 4v24a4 4 0 01-4 4H0V0z"
              class="colorize-background"
              style="fill: var(--color_background)"
            />
            <path d="M4 6h20v20H4z" />
            <path
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
              d="M19.833 16.833h-5v5h-1.666v-5h-5v-1.666h5v-5h1.666v5h5z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="radio-selected" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="radio-unselected" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="visible" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"
              class="colorize-fill"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="refresh" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10h-2c0 4.411-3.589 8-8 8s-8-3.589-8-8 3.589-8 8-8c2.206 0 4.203.9 5.65 2.35L15 9h7V2l-2.94 2.94A9.944 9.944 0 0012 2z"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="arrow-up" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              fill="currentColor"
              fill-rule="nonzero"
              d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="lock-open" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="lock-closed" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="forward" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              fill="currentColor"
              fill-rule="nonzero"
              d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="info" viewBox="0 0 24 24">
        <svg
          width="24px"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M11 7h2v2h-2V7zm0 4h2v6h-2v-6zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="done" viewBox="0 0 24 24">
        <svg
          width="24px"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g fill="none" fill-rule="evenodd" opacity="0.54">
            <path d="M0 0h24v24H0z" />
            <path
              fill="currentColor"
              fill-rule="nonzero"
              d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="cross" viewBox="0 0 24 24">
        <svg
          width="24px"
          height="24px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="currentColor"
              fill-rule="nonzero"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" />
          </g>
        </svg>
      </symbol>
      <symbol id="compare" viewBox="0 0 24 24">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
              d="M9.01 14H2v2h7.01v3L13 15l-3.99-4v3zm5.98-1v-3H22V8h-7.01V5L11 9l3.99 4z"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="darkmode" viewBox="0 0 24 24">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g fill="none" data-name="Layer 2">
            <path d="M0 0h24v24H0z" />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M5.17 12.62A5.25 5.25 0 1 0 12.6 5.2l-.2-.2A7 7 0 1 1 5 12.43Z"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="lightmode" viewBox="0 0 512 512">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M369.76 256a21.06 21.06 0 0 0-.23-3.62c-2.57-45.86-30.21-86.48-73.87-102.94-44.29-16.7-96.27-3-126.6 33.32s-35.39 89.51-11.29 130.66 72 62.43 118.65 54.42c53.4-9.16 90.48-56.15 93.2-108.88a21.84 21.84 0 0 0 .14-2.96ZM256 331.84c-41.46 0-75.75-34.45-75.84-75.84s34.52-75.84 75.84-75.84c42.21 0 74.72 34.46 75.84 75.84-1.12 41.37-33.62 75.84-75.84 75.84ZM445.6 237h-37.38c-24.39 0-24.44 37.92 0 37.92h37.38C470 275 470 237 445.6 237ZM103.78 237H66.4c-24.4 0-24.4 38 0 38h37.38c24.39 0 24.44-38 0-38ZM237 408.22v37.38c0 24.4 38 24.4 38 0v-37.38c0-24.39-38-24.44-38 0ZM275 103.78V66.4c0-24.4-38-24.4-38 0v37.38c0 24.39 38 24.44 38 0ZM377.05 161.77l26.43-26.43c7-7 7.54-19.89 0-26.81s-19.32-7.5-26.82 0L350.23 135c-7 7-7.54 19.88 0 26.81s19.32 7.45 26.82-.04ZM135 161.77c7 7 19.89 7.54 26.82 0s7.49-19.32 0-26.82l-26.43-26.42c-7-7-19.89-7.55-26.81 0s-7.5 19.32 0 26.81ZM135 350.23l-26.43 26.43c-7 7-7.54 19.89 0 26.81s19.32 7.5 26.81 0l26.4-26.47c7-7 7.54-19.89 0-26.81s-19.32-7.46-26.78.04ZM377.05 350.23c-7-7-19.89-7.55-26.81 0s-7.5 19.31 0 26.81l26.42 26.44c7 7 19.89 7.54 26.82 0s7.49-19.32 0-26.82Z"
          />
        </svg>
      </symbol>
      <symbol id="team" viewBox="0 0 18 18">
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0h18v18H0z" />
            <path
              d="M6.75 9a2.628 2.628 0 0 0 2.625-2.625A2.628 2.628 0 0 0 6.75 3.75a2.628 2.628 0 0 0-2.625 2.625A2.628 2.628 0 0 0 6.75 9Zm0-3.75c.622 0 1.125.503 1.125 1.125S7.372 7.5 6.75 7.5a1.123 1.123 0 0 1-1.125-1.125c0-.622.503-1.125 1.125-1.125Zm.037 7.5h-3.21C4.32 12.375 5.604 12 6.75 12c.082 0 .173.008.255.008.255-.548.697-.998 1.23-1.358a8.794 8.794 0 0 0-1.485-.15c-1.755 0-5.25.877-5.25 2.625v1.125h5.25v-1.125c0-.127.015-.255.037-.375Zm5.588-1.875c-1.38 0-4.125.758-4.125 2.25v1.125h8.25v-1.125c0-1.492-2.745-2.25-4.125-2.25Zm.908-1.365c.57-.322.967-.93.967-1.635a1.876 1.876 0 0 0-3.75 0c0 .705.398 1.313.967 1.635.27.15.578.24.908.24.33 0 .637-.09.908-.24Z"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="delta" viewBox="0 0 25 25">
        <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
          <g fill="none" fill-rule="evenodd" opacity=".8">
            <path d="M.5 0h24v24.533H.5z" />
            <path
              d="M12.5 7.943 18.89 18.4H6.11L12.5 7.943m0-3.854-10 16.355h20"
              fill="currentColor"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      </symbol>
      <symbol id="registered" viewBox="0 0 24 24">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><g fill="currentColor" fill-rule="nonzero"><path d="M10 4h4v4h-4zM4 16h4v4H4zM4 10h4v4H4zM4 4h4v4H4zM14 12.42V10h-4v4h2.42zM20.88 11.29l-1.17-1.17a.41.41 0 0 0-.58 0l-.88.88L20 12.75l.88-.88a.41.41 0 0 0 0-.58ZM11 18.25V20h1.75l6.67-6.67-1.75-1.75zM16 4h4v4h-4z"/></g></g></svg>
      </symbol>
      <symbol id="checked" viewBox="0 0 24 24">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path fill="currentColor" fill-rule="nonzero" d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2Zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9Z"/></g></svg>
      </symbol>
    </svg>
  </div>
</template>
