import StoreHelper from "../helper/store-helper";
import * as _ from "lodash";

export default {
  namespaced: true,
  state() {
    return {
      briefing: {},
      exerciseInstances: {},
      loading: false,
      errors: false,
    };
  },
  mutations: {
    setBriefing(state, payload) {
      state.briefing = payload;
    },
    setComparisonBriefing(state, payload) {
      const instances = {
        ...state.exerciseInstances,
      };
      instances[payload.id] = payload.response;
      state.exerciseInstances = instances;
    },
    clearComparisonBriefing(state) {
      state.exerciseInstances = {};
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    async loadBriefing(context, payload) {
      context.commit("setLoading", true);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}exercise/${payload.id}/briefing/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      StoreHelper.handleHttpError(response, context);

      context.commit("setBriefing", responseData);
      context.commit("setLoading", false);
    },

    /**
     * loads specific briefing data
     * for all exercises in compare mode
     */
    async loadCompareBriefings(context, payload) {
      context.commit("setLoading", true);
      const instanceIds = payload.id.split("+");
      context.commit("clearComparisonBriefing");

      const exerciseIteration = async (id) => {
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}exercise/${id}/briefing/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
          }
        );
        const responseData = await response.json();
        StoreHelper.handleHttpError(response, context);

        context.commit("setComparisonBriefing", {
          id,
          response: responseData,
          payload: payload,
        });
      };
      instanceIds.forEach((id) => {
        exerciseIteration(id);
      });
      context.commit("setLoading", false);
    },

    /**
     * will change data from briefing
     */
    async updateBriefing(context, payload) {
      if (payload && payload.data) {
        // select currently allowed values from data
        const allowedData = {
          align_strat: parseInt(payload.data.values.align_strat, 10),
          align_goal: parseInt(payload.data.values.align_goal, 10),
        };

        context.commit("setLoading", true);
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}exercise/${payload.id}/briefing/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(allowedData),
          }
        );
        // const responseData = await response.json();
        StoreHelper.handleHttpError(response, context);

        context.commit("setLoading", false);
      }
    },
  },
  getters: {
    briefing(state) {
      return state.briefing;
    },
    comparisonBriefings(state) {
      return _.sortBy(state.exerciseInstances, [
        (o, i) => {
          return i;
        },
      ]);
    },
    isLoading(state) {
      return state.loading;
    },
    errors(state) {
      return state.errors;
    },
  },
};
