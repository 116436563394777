import moment from "moment/moment";
import StoreHelper from "../helper/store-helper";

export default {
  namespaced: true,
  state() {
    return {
      lastLoadedCourseId: null,
      coursesAsTrainee: [],
      coursesAsTrainer: [],
      coursesAsSponsor: [],
      templatesAsTrainer: [],
      currentCourseDetails: {},
      currentCourse: null,
      currentCourseSummary: [],
      loadedCourse: null,
      loading: false,
      errors: false,
    };
  },
  mutations: {
    clear(state, payload) {
      state.lastLoadedCourseId = null;
      state.coursesAsTrainee = [];
      state.coursesAsTrainer = [];
      state.coursesAsSponsor = [];
      state.templatesAsTrainer = [];
      state.currentCourseDetails = {};
      state.currentCourse = null;
      state.currentCourseSummary = [];
      state.loadedCourse = null;
      state.loading = false;
      state.errors = false;
    },
    setLastLoadedCourseId(state, payload) {
      state.lastLoadedCourseId = payload;
    },
    setCourses(state, payload) {
      switch(payload.role) {
        case "sponsor":
          state.coursesAsSponsor = [ ...payload.courses ];
          break;
        case "trainer":
          if(payload.type === "template") {
            state.templatesAsTrainer = [ ...payload.courses ];
          }
          if(payload.type === "course" || payload.type === "all") {
            state.coursesAsTrainer = [ ...payload.courses ];
          }
          break;
        case "trainee":
          state.coursesAsTrainee = [ ...payload.courses ];
          break;
      }
    },
    setSummary(state, payload) {
      state.currentCourseSummary = payload.coursesummary;
      const startDate = payload.start_date;
      state.currentCourseDetails = {
        courseName: payload.course_name,
        instructor: payload.instructor,
        websessions: payload.websessions,
        coursesummary: payload.coursesummary,
        startDate,
        ready: payload.ready,
        currentUserConfirmed: payload.request_user_confirmed
      };
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    /**
     * will clear the state of
     * the store
     * @param {*} context 
     * @param {*} payload 
     */
    clear(context, payload) {
      context.commit("clear");
    },
    /**
     * load courses information of 
     * specific role
     * 
     * @param context
     * @param payload expects role to be set one of
     * sponsor, trainer, trainee
     */
    async loadCourses(context, payload) {
      let type = "all";
      let role = "trainee";
      if(payload.role) {
        role = payload.role;
      }
      if(payload.type && (payload.type === "course" || payload.type === "template")) {
        type = payload.type
      }
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}courses/?${(new URLSearchParams({ role, type }))}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          }
        }
      );
      const responseData = await response.json();
      responseData.role = role;
      responseData.type = type;
      StoreHelper.handleHttpError(response, context);
      context.commit("setCourses", responseData);
    },
    /**
     * load course summary
     */
    async loadSummary(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}courses/${payload.id}/summary/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      StoreHelper.handleHttpError(response, context);

      context.commit("setLastLoadedCourseId", payload.id);
      context.commit("setSummary", responseData);
    },
    clearErrors(context) {
      context.commit("setErrors", false);
    },
  },
  getters: {
    coursesAsTrainee(state) {
      return state.coursesAsTrainee;
    },
    coursesAsTrainer(state) {
      return state.coursesAsTrainer;
    },
    coursesAsSponsor(state) {
      return state.coursesAsSponsor;
    },
    templatesAsTrainer(state) {
      return state.templatesAsTrainer;
    },
    summary(state) {
      return state.currentCourseSummary;
    },
    courseDetails(state) {
      return state.currentCourseDetails;
    },
    lastLoadedCourseId(state) {
      return state.lastLoadedCourseId;
    },
    errors(state) {
      return state.errors;
    },
  },
};
