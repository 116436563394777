import { createStore } from "vuex";
import AuthModule from "./auth";
import TaskModule from "./task";
import BriefingModule from "./briefing";
import ExerciseModule from "./exercise";
import OptionsModule from "./options";
import ActivityModule from "./activity";
import EvaluationModule from "./evaluation";
import CourseModule from "./course";
import PasswordModule from "./password";
import ErrorModule from "./error";
import TrackingModule from "./tracking";
import CoursebuilderModule from "./coursebuilder";
import BuilderWebsessionModule from "./builder-websession";
import BuilderTemplatesModule from "./builder-templates";
import BuilderExercisesModule from "./builder-exercises";
import BuilderTraineesModule from "./builder-trainees";
import BuilderTraineesImportModule from "./builder-trainees-import";
import BuilderTrainerModule from "./builder-trainer";
import CompareWorkflowModule from "./compare-workflow";
import CarBriefingModule from "./car/briefing";
import CarExerciseModule from "./car/exercise";
import CarEvaluationModule from "./car/evaluation";

export default createStore({
  modules: {
    auth: AuthModule,
    task: TaskModule,
    briefing: BriefingModule,
    exercise: ExerciseModule,
    options: OptionsModule,
    activity: ActivityModule,
    evaluation: EvaluationModule,
    course: CourseModule,
    password: PasswordModule,
    error: ErrorModule,
    tracking: TrackingModule,
    coursebuilder: CoursebuilderModule,
    builderWebsession: BuilderWebsessionModule,
    builderTemplates: BuilderTemplatesModule,
    builderExercises: BuilderExercisesModule,
    builderTrainer: BuilderTrainerModule,
    builderTrainees: BuilderTraineesModule,
    builderTraineesImport: BuilderTraineesImportModule,
    compareWorkflow: CompareWorkflowModule,
    carBriefing: CarBriefingModule,
    carExercise: CarExerciseModule,
    carEvaluation: CarEvaluationModule
  },
  state: {},
  mutations: {},
  actions: {},
});
