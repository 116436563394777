import StoreHelper from "../helper/store-helper";
import FetchQueue from "../helper/fetch-queue";

export default {
  namespaced: true,
  state() {
    return {
      /**
       * the current courses
       * trainees array list
       */
      courseTrainees: [],
      /**
       * server response for last
       * remove user action
       */
      removeTraineesResponse: null,
      /**
       * server response for last
       * adding trainees action
       */
      courseTraineesResponseMessages: [],
      /**
       * contains errors for last action
       */
      errors: false,
      /**
       * loading switch
       */
      loading: false,
      /**
       * contains the response of the
       * resend  endpoint
       */
      resendResponse: null,
      /**
       * contains the response of the
       * request to confirm a trainees
       * invite confirmation
       */
      traineeInvitationConfirm: false,
    };
  },
  mutations: {
    /**
     * sets the response messages for the
     * last trainees post request
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseTraineesResponseMessages(state, payload) {
      state.courseTraineesResponseMessages = [ ...payload];
    },
    /**
     * sets the response of the last
     * remove user action for courses
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setRemoveTraineesResponse(state, payload) {
      state.removeTraineesResponse = payload;
    },
    /**
     * sets the course-trainees
     * object
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseTrainees(state, payload) {
      state.courseTrainees = [ ...payload.trainees ];
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
    /**
     * sets response of resend registration/ invitation
     * email endpoint
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setResendResponse(state, payload) {
      state.resendResponse = payload;
    },
    /**
     * set true or false to confirm the
     * confirmation of the users confirmation
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setTraineeInvitationConfirm(state, payload) {
      state.traineeInvitationConfirm = payload;
    },
  },
  actions: {
    async resetRemoveTraineesResponse(context) {
      // reset response switch
      context.commit("setRemoveTraineesResponse", "init");
    },
    async resetResendResponse(context) {
      // reset response switch
      context.commit("setResendResponse", "init");
    },
    /**
     * sets the confirmation state 
     * for an invite of a trainee
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    async confirmTrainee(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/confirm/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            payload.id
          ),
        }
      );
      const responseData = await response.status;
      if(responseData !== 200 && responseData !== 201) {
        throw new Error("Confirmation failed.");
      } else {
        context.commit("setTraineeInvitationConfirm", responseData === 200 ? true : false);
      }
    },
    /**
     * resend register mail for selected
     * users
     * payload expects a list of user ids in
     * attribute `users` and the course id
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    async resendRegisterEmail(context, payload) {
      if(payload && payload.users?.length && payload.course) {
        // map id array to resend endpoints format
        const resendArray = payload.users.map(id => {
          return {
            pk: id,
            course_invite: false,
            register: true,
          };
        });
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.course}/resend_invite/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              resendArray
            ),
          }
        );
        const responseData = await response.status;
        context.commit("setResendResponse", responseData == 200 ? true : false);
      } else {
        throw new Error("Failed resend register E-Mail due to wrong payload");
      }
    },
    /**
     * resend course invitation for selected
     * users
     * payload expects a list of user ids in
     * attribute `users` and the course id to reinvite
     * on in attribute `course` 
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    async resendInvitationEmail(context, payload) {
      if(payload && payload.users?.length && payload.course) {
        // map id array to resend endpoints format
        const resendArray = payload.users.map(id => {
          return {
            pk: id,
            course_invite: true,
            register: false,
          };
        });
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.course}/resend_invite/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              resendArray
            ),
          }
        );
        const responseData = await response.status;
        context.commit("setResendResponse", responseData == 200 ? true : false);
      } else {
        throw new Error("Failed resend course invitation E-Mail due to wrong payload");
      }
    },
    /**
     * loads a course with the given cid
     * in the payload
     * @param {*} context 
     * @param {*} payload 
     */
    async loadCourse(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      context.commit("setLoadedCourse", responseData);
    },
    /**
     * assigns a new trainee to the
     * the current course and replaces
     * existing
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async setCourseTrainees(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainees/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            payload.users
          ),
        }
      );
      const responseData = await response.json();
      context.commit("setCourseTrainees", responseData);
      context.commit("setCourseTraineesResponseMessages", responseData.messages);
    },
    /**
     * removes a trainee from
     * the current course
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async removeCourseTrainees(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainees/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            payload.users
          ),
        }
      );
      context.commit("setRemoveTraineesResponse", response.status);
    },
    /**
     * get all trainees of
     * the current course
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async getCourseTrainees(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainees/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          }
        }
      );
      const responseData = await response.json();
      context.commit("setCourseTrainees", responseData);
    },
    /**
     * will remove a user or users
     * by given ids e.g. users: [{id: 1}] of the
     * given course id
     * expect payload to contain cid, and users
     * property
     * 
     * @deprecated
     * @param {*} context 
     * @param {*} payload 
     */
    async removeAssignedUserFromCouse(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/add_users_to_course/${payload.cid}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            payload.users
          ),
        }
      );
      const responseData = await response.status;
      context.commit("setRemoveTraineesResponse", responseData);
    },
    /**
     * load the users of a course in
     * the context of course editation
     * 
     * @deprecated
     * @param {*} context 
     * @param {*} payload 
     */
    async loadCourseAssignedUsers(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/add_users_to_course/${payload.cid}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      context.commit("setCourseAssignedUsers", responseData);
    },
    /**
     * action to clear error store
     * 
     * @param {*} context 
     */
    clearErrors(context) {
      context.commit("setErrors", false);
    },
  },
  getters: {
    errors(state) {
      return state.errors;
    },
    courseTrainees(state) {
      return state.courseTrainees;
    },
    courseTraineesResponseMessages(state) {
      return state.courseTraineesResponseMessages;
    },
    removeTraineesResponse(state) {
      return state.removeTraineesResponse;
    },
    resendResponse(state) {
      return state.resendResponse;
    },
    traineeInvitationConfirm(state) {
      return state.traineeInvitationConfirm;
    },
  },
};
