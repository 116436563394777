import StoreHelper from "../helper/store-helper";
import FetchQueue from "../helper/fetch-queue";

const nextStepExerciseCall = async (payload, context) => {
  const apiCall = () => {
    return fetch(
      `${process.env.VUE_APP_CAT_API_URL}exercise/${payload.id}/next/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + context.rootGetters["auth/token"],
        },
      }
    );
  };
  return FetchQueue.enqueue(apiCall, "exercise_nextstep");
};

export default {
  namespaced: true,
  state() {
    return {
      currentInitiatedExercise: null,
      currentExerciseId: null,
      briefing: {},
      exerciseData: {},
      gaps: {},
      state: {},
      loading: false,
      errors: false,
      exerciseStarted: false,
      exerciseInitiated: false,
      hipkneeangle: null,
      softtissueBlockedTo: null,
      briefingUpdated: false,
    };
  },
  mutations: {
    setBriefingUpdated(state, payload) {
      state.briefingUpdated = payload;
    },
    setSofttissueBlockedTo(state, payload) {
      state.softtissueBlockedTo = parseInt(payload);
    },
    setCurrentInitiatedExercise(state, payload) {
      state.currentInitiatedExercise = parseInt(payload);
    },
    setCurrentExerciseId(state, payload) {
      state.currentExerciseId = parseInt(payload);
    },
    setBriefing(state, payload) {
      if (Object.keys(state.briefing).length == 0) {
        // first briefing load sets exercise as initiated for store
        state.exerciseInitiated = true;
      }
      if (payload) {
        state.briefing = payload;
      }
    },
    setCurrentState(state, payload) {
      if (payload.tibia_start || payload.femur_start) {
        state.exerciseStarted = true;
      } else {
        state.exerciseStarted = false;
      }
      state.state = payload;
    },
    resetExercise(state) {
      state.exerciseData = {};
      state.gaps = {};
      state.state = {};
      state.loading = false;
      state.errors = false;
      state.exerciseStarted = false;
      state.exerciseInitiated = false;
      state.hipkneeangle = null;
      state.currentExerciseId = null;
    },
    setExercise(state, payload) {
      if (payload.response.gaps) {
        state.gaps = { ...payload.response.gaps };
      }

      if (payload.response.hka !== undefined) {
        state.hipkneeangle = payload.response.hka;
        delete payload.response.hka;
      }

      const data = payload.response;
      data.softtissue = parseInt(payload.response.tibia.softtissue, 10);


      // map insert_height
      if(payload.insertHeightMapping) {
        const attrOptions = JSON.parse(localStorage.getItem("attributeOptions"));
        const insertHeightMappingFlipped = Object.fromEntries(Object.entries(attrOptions.insert_height_to_mm).map(a => a.reverse()));
        data.tibia.insert_height = insertHeightMappingFlipped[data.tibia.insert_height];
      }
      // add insert_height if it is not present yet
      // setting it to a predefined value
      if(data.tibia.insert_height === undefined) {
        data.tibia.insert_height = "No";
      }

      state.exerciseData = data;
      state.state = data.state;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
    setStartExercise(state, payload) {
      state.exerciseStarted = payload;
      state.hipkneeangle = null;
    },
  },
  actions: {
    setSofttissueBlockedTo(context, payload) {
      context.commit("setSofttissueBlockedTo", payload);
    },
    async initiateInstance(context, payload) {
      if (payload.exerciseId && payload.index) {
        context.commit("setLoading", true);
        const apiCall = () => {
          return fetch(
            `${process.env.VUE_APP_CAT_API_URL}exercise/instance/${payload.exerciseId}/${payload.index}/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "token " + context.rootGetters["auth/token"],
              },
            }
          );
        };
        const response = await FetchQueue.enqueue(apiCall, "initinstance");
        const responseData = await response.json();

        StoreHelper.handleHttpError(response, context);
        context.commit(
          "setCurrentInitiatedExercise",
          parseInt(responseData.id)
        );
        context.commit("setLoading", false);
      }
    },
    async resetExerciseStep(context, payload) {
      if (payload.step) {
        context.commit("setLoading", true);
        const apiCall = () => {
          return fetch(
            `${process.env.VUE_APP_CAT_API_URL}exercise/${payload.id}/${payload.step}/reset/`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "token " + context.rootGetters["auth/token"],
              },
            }
          );
        };
        const response = await FetchQueue.enqueue(apiCall, "resetexercise");
        StoreHelper.handleHttpError(response, context);
        context.commit("setLoading", false);
      }
    },
    async updateExercise(context, payload) {
      if (
        // context.getters.exerciseStarted &&
        payload.data !== undefined &&
        payload.step &&
        payload.id
      ) {
        context.commit("setLoading", true);
        // rearrange disordered softtissue
        let exerciseData = {};

        // add gaps
        exerciseData.tibia = { ...payload.data.tibia };
        exerciseData.femur = { ...payload.data.femur };
        // onload softtissue into tibia
        exerciseData.tibia.softtissue = parseInt(payload.data.softtissue, 10);

        // fix backend error
        if (exerciseData.tibia.created === undefined) {
          exerciseData.tibia.created = new Date(); //"2021-04-07T18:32:29.299540Z"
        }
        if (exerciseData.femur.created === undefined) {
          exerciseData.femur.created = exerciseData.tibia.created;
        }

        // convert integer to the mapped value for the backend before
        // sending the dataset
        if(Number.isInteger(exerciseData.tibia.insert_height)) {
          const attrOptions = JSON.parse(localStorage.getItem("attributeOptions"));
          const insertHeightMappingFlipped = Object.fromEntries(Object.entries(attrOptions.insert_height_to_mm).map(a => a.reverse()));
          exerciseData.tibia.insert_height = insertHeightMappingFlipped[exerciseData.tibia.insert_height];
        }

        const apiCall = () => {
          return fetch(
            `${process.env.VUE_APP_CAT_API_URL}exercise/${payload.id}/input/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "token " + context.rootGetters["auth/token"],
              },
              body: JSON.stringify(exerciseData),
            }
          );
        };
        const response = await FetchQueue.enqueue(apiCall, "update");
        const responseData = await response.json();
        StoreHelper.handleHttpError(response, context);

        context.commit("setExercise", {
          response: responseData,
          payload: payload,
          insertHeightMapping: false
        });
        context.commit("setLoading", false);
      }
    },
    async nextExerciseStep(context, payload) {
      context.commit("setLoading", true);
      const response = await nextStepExerciseCall(payload, context);
      const responseData = await response.json();

      context.commit("setCurrentState", responseData.state);
      context.commit("setLoading", false);
    },
    async closeConsideration(context, payload) {
      context.commit("setLoading", true);
      await fetch(
        `${process.env.VUE_APP_CAT_API_URL}exercise/${payload.exercise}/consideration/${payload.consideration}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      context.commit("setLoading", false);
    },
    async globalReset(context, payload) {
      context.commit("setLoading", true);

      const apiCall = () => {
        return fetch(
          `${process.env.VUE_APP_CAT_API_URL}exercise/${payload.id}/full_reset/${payload.userId}/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
          }
        );
      };
      const response = await FetchQueue.enqueue(apiCall, "globalexercisereset");
      StoreHelper.handleHttpError(response, context);
      context.commit("resetExercise");
      // clear tracking for this exercise
      if (payload.exerciseIds) {
        payload.exerciseIds.forEach((id) => {
          context.dispatch(
            "tracking/resetExerciseTracking",
            { exerciseId: id },
            { root: true }
          );
        });
      }
      context.commit("setLoading", false);
    },
    async loadBriefing(context, payload) {
      context.commit("setBriefingUpdated", false);
      context.commit("setLoading", true);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}exercise/${payload.id}/briefing/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      StoreHelper.handleHttpError(response, context);
      context.commit("resetExercise");
      context.commit("setCurrentExerciseId", payload.id);
      context.commit("setBriefing", responseData);
      context.commit("setCurrentState", responseData.state);

      context.commit("setExercise", {
        response: responseData,
        payload: {
          femur: { ...payload.femur },
          tibia: { ...payload.tibia },
          consider: { ...payload.consider },
        },
        insertHeightMapping: true
      });
      context.commit("setLoading", false);
    },

    /**
     * will change data from briefing
     */
    async updateBriefing(context, payload) {
      if (payload && payload.data) {
        // select currently allowed values from data
        const allowedData = {
          align_strat: parseInt(payload.data.values.align_strat, 10),
          align_goal: parseInt(payload.data.values.align_goal, 10),
        };

        context.commit("setLoading", true);
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}exercise/${payload.id}/briefing/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(allowedData),
          }
        );
        if(response.status == 204 || response.status == 200) {
          context.commit("setBriefingUpdated", true);
        }
        StoreHelper.handleHttpError(response, context);
        context.commit("setLoading", false);
      }
    },
  },
  getters: {
    briefing(state) {
      return state.briefing;
    },
    currentExerciseId(state) {
      return state.currentExerciseId;
    },
    currentInitiatedExercise(state) {
      return state.currentInitiatedExercise;
    },
    exerciseData(state) {
      return state.exerciseData;
    },
    state(state) {
      return state.state;
    },
    hipkneeangle(state) {
      return state.hipkneeangle;
    },
    gaps(state) {
      return state.gaps;
    },
    isLoading(state) {
      return state.loading;
    },
    exerciseStarted(state) {
      return state.exerciseStarted;
    },
    exerciseInitiated(state) {
      return state.exerciseInitiated;
    },
    softtissueBlockedTo(state) {
      return state.softtissueBlockedTo;
    },
    briefingUpdated(state) {
      return state.briefingUpdated;
    }
  },
};
