export default class FetchQueue {
  // delete previous items in queue to use only the last (latest)
  static lastInLastOut = true;
  static useQueue = true;
  static queue = [];
  static stop = false;
  static lastAddedType = null;

  static clearAllQueue() {
    this.queue = [];
    this.lastAddedType = null;
  }

  static enqueue(promise, type) {
    if (!this.useQueue) {
      return promise();
    }
    return new Promise((resolve, reject) => {
      this.lastAddedType = type;
      /**
       * promise to process queue and
       * search for same type
       */
      const waitForCleanup = new Promise((resolve) => {
        if (this.queue.length) {
          this.queue.forEach((item, index, arr) => {
            if (item.type == type && this.lastInLastOut) {
              this.queue.splice(index, 1);
            }
            if (index === arr.length - 1) resolve();
          });
        } else {
          resolve();
        }
      });
      waitForCleanup.then(() => {
        // wait for above cleanup to finish
        this.queue.push({
          promise,
          resolve,
          reject,
          type,
        });
        this.process();
      });
    });
  }

  static process() {
    if (this.workingOnPromise) {
      return false;
    }
    if (this.stop) {
      this.queue = [];
      this.stop = false;
      return;
    }
    const item = this.queue.shift();
    if (!item) {
      return false;
    }
    try {
      this.workingOnPromise = true;
      item
        .promise()
        .then((value) => {
          this.workingOnPromise = false;
          if (
            item.type == this.lastAddedType &&
            this.lastInLastOut &&
            this.queue.length
          ) {
            // skip running promise if the last added item type is
            // the same to prevent glitches but only if it is not the last promise
            // item.reject("newer promise added");
          } else {
            this.lastAddedType = null;
            item.resolve(value);
          }
          this.process();
        })
        .catch((err) => {
          this.workingOnPromise = false;
          item.reject(err);
          this.process();
        });
    } catch (err) {
      this.workingOnPromise = false;
      item.reject(err);
      this.process();
    }
    return true;
  }
}
