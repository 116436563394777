export default {
  namespaced: true,
  state() {
    return {
      currentTracking: null,
      currentTrackingTimes: null,
      trackingSending: false,
      lastTrackingSuccessfullySent: false,
    };
  },
  mutations: {
    setTrackingStartTime(state, payload) {
      let currentTrackingTimes = {};
      if (state.currentTrackingTimes !== null) {
        currentTrackingTimes = { ...state.currentTrackingTimes };
      }
      currentTrackingTimes[payload.exerciseId] = payload.datetime;
      state.currentTrackingTimes = currentTrackingTimes;
    },
    setTracking(state, payload) {
      const id = payload.exerciseId;
      const data = [...payload.data];

      const updatedTracking = { ...state.currentTracking };

      updatedTracking[id] = data;
      state.currentTracking = updatedTracking;
      localStorage.setItem("currentTracking", JSON.stringify(updatedTracking));
    },
    loadTrackingFromStorage(state, payload) {
      state.currentTracking = payload;
    },
    clearTracking(state) {
      state.currentTracking = null;
      state.currentTrackingTimes = null;
      state.trackingSending = false;
      state.lastTrackingSuccessfullySent = false;
      localStorage.removeItem("currentTracking");
    },
    clearTrackingForExercise(state, payload) {
      let currentTrackingTimes = {};
      if (state.currentTrackingTimes !== null) {
        currentTrackingTimes = { ...state.currentTrackingTimes };
      }
      currentTrackingTimes[payload.exerciseId] = null;
      state.currentTrackingTimes = currentTrackingTimes;

      const updatedTracking = { ...state.currentTracking };
      updatedTracking[payload.exerciseId] = null;
      state.currentTracking = updatedTracking;
    },
    setTrackingSending(state) {
      state.trackingSending = true;
    },
    setTrackingResult(state, payload) {
      state.trackingSending = false;
      state.lastTrackingSuccessfullySent = payload.result;
    },
  },
  actions: {
    /**
     * reset exercise specific tracking
     * only
     *
     * @param {*} context
     * @param {string} payload
     */
    resetExerciseTracking(context, payload = null) {
      let id = context.rootGetters["exercise/currentExerciseId"];
      if (payload !== null && payload.exerciseId) {
        id = payload.exerciseId;
      }
      context.commit("clearTrackingForExercise", {
        exerciseId: id,
      });
    },

    /**
     * updates the local tracking data
     *
     * @param {*} context
     * @param {string} payload
     */
    async resetTracking(context) {
      context.commit("clearTracking");
      context.commit("setTrackingStartTime", {
        datetime: 0,
        exerciseId: context.rootGetters["exercise/currentExerciseId"],
      });
    },

    /**
     * updates the local tracking data
     *
     * @param {*} context
     * @param {string} payload
     */
    async updateTracking(context, payload) {
      let exerciseId = context.rootGetters["exercise/currentExerciseId"];
      // only start tracking update, if we are in a exercise
      if (exerciseId > 0) {
        // check if we are starting the tracking
        let tracking = [];
        let startTime = payload.datetime;

        if (context.getters["currentTracking"] === null) {
          const ls = localStorage.getItem("currentTracking");
          if (ls) {
            const savedTracking = JSON.parse(ls);
            context.commit("loadTrackingFromStorage", savedTracking);
            tracking = savedTracking[exerciseId];
            if (!tracking) {
              // a new tracking maybe null so force to a empty array
              tracking = [];
            }
          }
          const datetime = new Date().getTime();
          context.commit("setTrackingStartTime", {
            exerciseId: context.rootGetters["exercise/currentExerciseId"],
            datetime,
          });
          startTime = datetime;
        } else {
          if (!context.getters["currentTrackingTimes"][exerciseId]) {
            const datetime = new Date().getTime();
            context.commit("setTrackingStartTime", {
              exerciseId: context.rootGetters["exercise/currentExerciseId"],
              datetime,
            });
            startTime = datetime;
          }
          if (
            context.getters["currentTracking"][exerciseId] &&
            context.getters["currentTrackingTimes"][exerciseId]
          ) {
            tracking = [...context.getters["currentTracking"][exerciseId]];
            startTime = context.getters["currentTrackingTimes"][exerciseId];
          }
        }
        // prepare data to be pushed to current tracking
        const diffTime = parseInt(payload.datetime) - parseInt(startTime);
        payload.time = diffTime < 0 ? 0 : diffTime;
        tracking.push(payload);

        // receive tracking node and append it to the other
        context.commit("setTracking", {
          exerciseId: context.rootGetters["exercise/currentExerciseId"],
          data: tracking,
        });
      }
    },

    /**
     * sends tracking to the server
     *
     * @param {*} context
     * @param {string} payload
     */
    async sendTracking(context) {
      context.commit("setTrackingSending");
      if (
        context.getters["currentTracking"][
          context.rootGetters["exercise/currentExerciseId"]
        ]
      ) {
        const data =
          context.getters["currentTracking"][
            context.rootGetters["exercise/currentExerciseId"]
          ];

        const formData = new FormData();
        formData.append("user", context.rootGetters["auth/me"]?.id);
        formData.append(
          "exercise",
          context.rootGetters["exercise/currentExerciseId"]
        );
        formData.append("json", JSON.stringify(data));

        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}usage_tracking/`,
          {
            method: "POST",
            headers: {
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: formData,
          }
        );
        if (!response.ok) {
          context.commit("setTrackingResult", response.ok);
        }
      }
    },

    /**
     * get tracking data for a
     * users execercise
     *
     * @param {*} context
     * @param {string} payload
     */
    async trackingData(context, id) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}usage_tracking/${id}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      await response.json();
      if (!response.ok) {
        context.commit("setLastErrorSentSuccessfullState");
      }
    },
  },
  getters: {
    currentTrackingTimes(state) {
      return state.currentTrackingTimes;
    },
    trackingSending(state) {
      return state.trackingSending;
    },
    currentTracking(state) {
      return state.currentTracking;
    },
    lastTrackingSuccessfullySent(state) {
      return state.lastTrackingSuccessfullySent;
    },
  },
};
