import FetchQueue from "../helper/fetch-queue";

export default {
  namespaced: true,
  state() {
    return {
      lastError: null,
      lastErrorSending: false,
      lastErrorSuccessfullySent: false,
    };
  },
  mutations: {
    setLastErrorSendingState(state, payload) {
      state.lastError = payload;
      state.lastErrorSending = true;
      state.lastErrorSuccessfullySent = false;
    },
    setLastErrorSentSuccessfullState(state) {
      state.lastErrorSending = false;
      state.lastErrorSuccessfullySent = true;
    },
  },
  actions: {
    /**
     * report error sends any error message
     * to the backend to report it to the
     * dev tem
     *
     * @param {*} context
     * @param {string} payload
     */
    async report(context, payload) {
      console.log(payload.error.stack);
      context.commit("setLastErrorSendingState", payload);
      const message = payload.error.stack;

      // clear queue if no connection can be established
      if (payload.error.message == "Failed to fetch") {
        FetchQueue.clearAllQueue();
      }

      const response = await fetch(`${process.env.VUE_APP_CAT_API_URL}error/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + context.rootGetters["auth/token"],
        },
        body: JSON.stringify({
          error_text: message,
          path: window.location.pathname,
        }),
      });
      await response.json();
      if (!response.ok) {
        context.commit("setLastErrorSentSuccessfullState");
      }
    },
  },
  getters: {
    lastError(state) {
      return state.lastError;
    },
    lastErrorSending(state) {
      return state.lastErrorSending;
    },
    lastErrorSuccessfullySent(state) {
      return state.lastErrorSuccessfullySent;
    },
  },
};
