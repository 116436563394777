export default {
  namespaced: true,
  state() {
    return {
      backLink: {},
      history: [], // navigation history
      activeExercise: {}, // at least id, name, started_at,
      // exerciseData: {},
      errors: false,
      trainerInTraineeMode: false,
      trainerInTraineeCourse: false,
      currentParentType: 'course'
    };
  },
  mutations: {
    setCurrentBackLink(state, payload) {
      state.backLink = payload;
    },
    setHistory(state, payload) {
      state.history = [...payload];
    },
    addToHistory(state, payload) {
      state.history = [...state.history].push(payload);
    },
    resetHistory(state) {
      state.history = [];
    },
    setActiveExercise(state, payload) {
      state.activeExercise = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setTrainerInTraineeCourse(state, payload) {
      state.trainerInTraineeCourse = payload;
      state.trainerInTraineeMode = true;
    },
    setTrainerInTraineeMode(state, payload) {
      state.trainerInTraineeMode = payload;
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
    /**
     * set the current parent type
     * to indicate if we are viewing
     * trainee mode or anything else
     * of a course for a course or template
     */
    setCurrentParentType(state, payload) {
      state.currentParentType = payload;
    },
    /**
     * set the current ids of parents
     * to keep track of the course / template
     * data if we are in trainee mode
     */
    setCurrentParentIds(state, payload) {
      state.currentParentIds = payload;
    }
  },
  actions: {
    setCurrentParentCourseType(context, type) {
      context.commit("setCurrentParentType", type);
    },
    setCurrentParentIds(context, data) {
      context.commit("setCurrentParentIds", data);
    },
    setCurrentBackLink(context, link) {
      context.commit("setCurrentBackLink", link);
    },
    setHistory(context, items) {
      context.commit("setHistory", items);
    },
    /**
     * add history item with object like
     * {
     *  key: 0,
     *  label: this.briefing.values.name || "Exercise",
     *  class: "",
     *  link: this.handleBackNav(),
     *  },
     * @param {a} context 
     * @param {*} item 
     */
    addToHistory(context, item) {
      context.commit("addToHistory", item);
    },
    /**
     * sets trainerInTraineeCourse to
     * define the current active course
     * for a trainee mode
     */
    setCurrentTrainerTraineeModeCourse(context, courseId) {
      // persist current data
      localStorage.setItem("trainerInTraineeCourse", JSON.stringify(courseId));
      context.commit("setTrainerInTraineeCourse", courseId);
    },
    /**
     * disables the trainee mode
     * for trainer users
     */
    exitTrainerInTraineeMode(context) {
      // persist current data
      context.commit("setTrainerInTraineeMode");
    },
    updateActiveExerciseState(context, payload) {
      // persist current data
      localStorage.setItem("activeExercise", JSON.stringify(payload));

      context.commit("setActiveExercise", payload);
    },
    /**
     * checks is active exercise data is already
     * set in localStorage to initally load it into
     * the store
     */
    checkActivity(context) {
      const savedData = localStorage.getItem("activeExercise");
      if (savedData) {
        context.commit("setActiveExercise", JSON.parse(savedData));
      }
    },
  },
  getters: {
    backLink(state) {
      return state.backLink;
    },
    history(state) {
      return state.history;
    },
    activeExercise(state) {
      return state.activeExercise;
    },
    errors(state) {
      return state.errors;
    },
    trainerInTraineeMode(state) {
      return state.trainerInTraineeMode;
    },
    trainerInTraineeCourse(state) {
      if (!state.trainerInTraineeCourse) {
        return JSON.parse(localStorage.getItem("trainerInTraineeCourse"));
      }
      return state.trainerInTraineeCourse;
    },
    currentParentType(state) {
      return state.currentParentType;
    },
    currentParentIds(state) {
      return state.currentParentIds;
    }
  },
};
