import StoreHelper from "../../helper/store-helper";
import * as _ from "lodash";

export default {
  namespaced: true,
  state() {
    return {
      briefing: {},
      briefingValidation: {},
      strategyEvaluationUserSet: JSON.parse(localStorage.getItem('carStrategyEvaluationUserSet')),
      // defines fields that are set by the new
      // evaluation step 
      fieldsSetByEvaluation: [],
      exerciseInstances: {},
      loading: false,
      errors: false,
    };
  },
  mutations: {
    /**
     * boolean, set true, if the
     * user already set the strategy
     * and goal with help of the evaluation
     */
    setStrategyEvaluationUserSet(state, payload) {
      localStorage.setItem("carStrategyEvaluationUserSet", JSON.stringify(payload));
      state.strategyEvaluationUserSet = payload;
    },
    /**
     * payload expects to be an 
     * array with field names that
     * are to be hidden in briefing
     * in order to be set through 
     * a new dialog within the exercise
     * step
     */
    setFieldsToSetByEvaluation(state, payload) {
      state.fieldsSetByEvaluation = [...payload];
    },
    setBriefingValidation(state, payload) {
      state.briefingValidation = { ...payload };
    },
    setBriefing(state, payload) {
      state.briefing = payload;
    },
    setComparisonBriefing(state, payload) {
      const instances = {
        ...state.exerciseInstances,
      };
      instances[payload.id] = payload.response;
      state.exerciseInstances = instances;
    },
    clearComparisonBriefing(state) {
      state.exerciseInstances = {};
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    /**
     * sets a boolean; true if the user
     * already set the strategy
     */
    async setStrategyEvaluationUserSet(context, payload) {
      const newDataset = {
        ...context.rootGetters["briefingCar/strategyEvaluationUserSet"]
      };
      newDataset[payload.id] = payload.value;
      context.commit("setStrategyEvaluationUserSet", newDataset);
    },
    async resetStrategyEvaluationUserSet(context, payload) {
      // unset the evaluation set state for strategy
      let newDataset = {
        ...context.rootGetters["briefingCar/strategyEvaluationUserSet"]
      };
      if(newDataset[payload.id] !== undefined) {
        newDataset = _.omit(newDataset[payload.id]);
      } 
      context.commit("setStrategyEvaluationUserSet", newDataset);
    },
    async setFieldsToSetByEvaluation(context, payload) {
      context.commit("setFieldsToSetByEvaluation", payload);
    },
    /**
     * patches the briefing with the
     * given data
     */
    async patchBriefing(context, payload) {
      let currentBriefing = context.rootGetters["briefing/briefing"];
      context.dispatch("updateBriefing", _.merge(currentBriefing, payload));
    },
    /**
     * will set data of briefing
     * for knee car specific cas
     */
    async updateBriefing(context, payload) {
      if (payload && payload.data) {
        context.commit("setLoading", true);
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}car/exercise/${payload.id}/briefing/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(payload.data),
          }
        );
        const responseData = await response.json();

        /**
         * retrieve validation messages
         * from backend to pass it to the 
         * fields
         */
        if(response.status == 400) {
          context.commit("setBriefingValidation", responseData);
        }
        if(response.status > 400) {
          StoreHelper.handleHttpError(response, context);
        }
        if(response.status == 200) {
          context.commit("setBriefingValidation", []);
        }

        context.commit("setLoading", false);
      }
    },
  },
  getters: {
    briefing(state) {
      return state.briefing;
    },
    isLoading(state) {
      return state.loading;
    },
    errors(state) {
      return state.errors;
    },
    briefingValidation(state) {
      return state.briefingValidation;
    },
    fieldsSetByEvaluation(state) {
      return state.fieldsSetByEvaluation;
    },
    strategyEvaluationUserSet(state) {
      return state.strategyEvaluationUserSet;
    }
  },
};
