import StoreHelper from "../helper/store-helper";

export default {
  namespaced: true,
  state() {
    return {
      tasks: [],
      currentTraineeTasks: {},
      loading: false,
      errors: false,
    };
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setTasks(state, payload) {
      state.tasks = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    async loadTasks(context, payload) {
      context.commit("setLoading", true);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}course/${payload.courseId}/exercises/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      StoreHelper.handleHttpError(response, context);

      context.commit("setTasks", responseData);
      context.commit("setLoading", false);
    },
    clearErrors(context) {
      context.commit("setErrors", false);
    },
  },
  getters: {
    tasks(state) {
      return state.tasks;
    },
    currentTraineeTasks(state) {
      return state.currentTraineeTasks;
    },
    errors(state) {
      return state.errors;
    },
    isLoading(state) {
      return state.loading;
    },
  },
};
