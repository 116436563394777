export default {
  namespaced: true,
  state() {
    return {
      /**
       * contains the currently assigned
       * trainees to the loaded course
       * currentHandledCourseId
       */
      currentCourseTrainees: [],
      /**
       * the file object from the
       * prepare request which is used
       * to get the file headers
       */
      currentHandledFile: null,
      /**
       * the currently handled course
       * which is used for the trainees import
       */
      currentHandledCourseId: null,
      /**
       * response from endpoint where the
       * file was uploaded to check and
       * return head columns for mapping
       */
      fileMappingDataResponse: null,
      /**
       * the mapping result
       * (importable dataset) after setting
       * the file and mapping data
       */
      mappingResult: null,
      /**
       * contains errors for last action
       */
      errors: false,
      /**
       * loading switch
       */
      loading: false
    };
  },
  mutations: {
    setCurrentCourseTrainees(state, payload) {
      state.currentCourseTrainees = [ ...payload.trainees ];
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setCurrentHandledCourseId(state, payload) {
      state.currentHandledCourseId = payload;
    },
    setCurrentHandledFile(state, payload) {
      state.currentHandledFile = payload;
    },
    setFileMappingDataResponse(state, payload) {
      state.fileMappingDataResponse = { ...payload };
    },
    setMappingResult(state, payload) {
      state.mappingResult = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      } else {
        state.errors.push({
          status: payload.status,
          message: payload.message,
        });
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    /**
     * get all trainees of
     * the current course
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async getCurrentCourseTrainees(context, payload) {
      context.commit("setCurrentHandledCourseId", payload.cid);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainees/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          }
        }
      );
      const responseData = await response.json();
      context.commit("setCurrentCourseTrainees", responseData);
    },
    /**
     * will reset all states for the
     * current process of the import
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async resetProcessState(context, payload) {
      context.commit("setLoading", false);
      context.commit("setErrors", false);
      context.commit("setFileMappingDataResponse", null);
      context.commit("setCurrentHandledFile", null);
      context.commit("setCurrentHandledCourseId", null);
    },
    /**
     * uploads the file with trainees
     * to import and waits for the mapping
     * information to respond
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    async uploadFileAndPrepare(context, payload) {
      context.commit("setLoading", true);
      context.commit("setCurrentHandledCourseId", payload.id);
      context.commit("setCurrentHandledFile", payload.file);

      var data = new FormData();
      data.append('file', payload.file);
      data.append('id', payload.id);

      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.id}/trainees/file_import/prepare/`,
        {
          method: "POST",
          headers: {
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: data,
        }
      );
      const responseStatus = await response.status;
      const responseData = await response.json();
      context.commit("setLoading", false);
      if(responseStatus === 200) {
        context.commit("setFileMappingDataResponse", responseData);
      } else {
        context.commit("setErrors", responseData);
        throw new Error("Upload failed.");
      }
    },
    /**
     * requests the start of the import of
     * previous file with the information about
     * mapping of the files
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    async setMappingAndStart(context, payload) {
      context.commit("setLoading", true);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.id}/trainees/file_import/start`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify({
            ...payload.mapping,
          }),
        }
      );
      const responseStatus = await response.status;
      const responseData = await response.json();
      context.commit("setLoading", false);
      if(responseStatus === 200) {
        context.commit("setMappingResult", responseData);
      } else {
        context.commit("setError", responseData);
        throw new Error("Mapping failed.");
      }
    },
    /**
     * action to clear error store
     * 
     * @param {*} context 
     */
    clearErrors(context) {
      context.commit("setErrors", false);
    },
  },
  getters: {
    errors(state) {
      return state.errors;
    },
    loading(state) {
      return state.loading;
    },
    fileMappingDataResponse(state) {
      return state.fileMappingDataResponse;
    },
    currentHandledCourseId(state) {
      return state.currentHandledCourseId;
    },
    currentHandledFile(state) {
      return state.currentHandledFile;
    },
    mappingResult(state) {
      return state.mappingResult;
    },
    currentCourseTrainees(state) {
      return state.currentCourseTrainees;
    }
  },
};
