import StoreHelper from "../../helper/store-helper";
import * as _ from "lodash";
export default {
  namespaced: true,
  state() {
    return {
      chronologicData: {},
      input: {},
      target: {},
      cpakOrigin: {},
      cpakTarget: {},
      currentTraineeEvaluation: {},
      exerciseInstances: {},
      exception: null,
      loading: false,
      errors: false,
    };
  },
  mutations: {
    setEvaluation(state, payload) {
      state.chronologicData = { ...payload.response };
      /*
      state.input = payload.response.input;
      state.target = payload.response.target;
      state.hka = payload.response.target?.Hip_knee_angle;
      state.hkaTarget = payload.response.target?.Hip_knee_angle_target;
      state.cpakOrigin = payload.response.target?.CPAK;
      state.cpakTarget = payload.response.target?.CPAK_target;
      state.exception = payload.response.Exception;
      state.currentTraineeEvaluation = {
        instructor: payload.response.instructor,
        courseName: payload.response.course_name,
        trainee: payload.response.trainee,
        trainee_email: payload.response.trainee_email,
        websessions: payload.websessions,
      };
      */
    },
    setComparison(state, payload) {
      const instances = {
        ...state.exerciseInstances,
      };
      if (payload.response) {
        const instanceObject = {
          hka: payload.response.Hip_knee_angle,
          hkaTarget: payload.response.Hip_knee_angle_target,
          CPAK: payload.response.CPAK,
          CPAK_target: payload.response.CPAK_target,
          Hip_knee_angle: payload.response.Hip_knee_angle,
          Hip_knee_angle_target: payload.response.Hip_knee_angle_target,
          exception: payload.response.Exception,
          currentTraineeEvaluation: {
            instructor: payload.response.instructor,
            courseName: payload.response.course_name,
            trainee: payload.response.trainee,
            trainee_email: payload.response.trainee_email,
            websessions: payload.websessions,
          },
        };
        instances[payload.id] = instanceObject;
      } else {
        instances[payload.id] = false;
      }
      state.exerciseInstances = instances;
    },
    clearComparison(state) {
      state.exerciseInstances = {};
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    async loadEvaluation(context, payload) {
      context.commit("setLoading", true);
      const gk = payload.gk ? payload.gk : 0;

      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}car/exercise/${payload.id}/op_evaluation/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      StoreHelper.handleHttpError(response, context);

      context.commit("setEvaluation", {
        response: responseData,
        payload: payload,
      });
      context.commit("setLoading", false);
    },
    async loadComparison(context, payload) {
      context.commit("setLoading", true);
      const gk = payload.gk ? payload.gk : 0;
      // for comparison will always be 0 by now
      const adjustedData = payload.adjustedData ? payload.adjustedData : 0;
      const instanceIds = payload.id.split("+");
      context.commit("clearComparison");

      const exerciseIteration = async (id) => {
        fetch(
          `${process.env.VUE_APP_CAT_API_URL}exercise/${id}/evaluation/${gk}/${adjustedData}/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
          }
        )
          .then((response) => {
            if (response.ok) {
              response.json().then((responseData) => {
                StoreHelper.handleHttpError(response, context);
                context.commit("setComparison", {
                  id,
                  response: responseData,
                  payload: payload,
                });
              });
            } else if (response.status >= 500) {
              // this error might occur if the exercise is not yet finished
              context.commit("setComparison", {
                id,
                response: false,
                payload: payload,
              });
            }
          })
          .catch(() => {
            context.commit("setComparison", {
              id,
              response: false,
              payload: payload,
            });
          });
      };
      instanceIds.forEach((id) => {
        exerciseIteration(id);
      });
      context.commit("setLoading", false);
    },
  },
  getters: {
    input(state) {
      return state.input;
    },
    target(state) {
      return state.target;
    },
    hka(state) {
      return state.hka;
    },
    hkaTarget(state) {
      return state.hkaTarget;
    },
    cpakOrigin(state) {
      return state.cpakOrigin;
    },
    cpakTarget(state) {
      return state.cpakTarget;
    },
    exception(state) {
      return state.exception;
    },
    comparison(state) {
      return _.sortBy(state.exerciseInstances, [
        (o, i) => {
          return i;
        },
      ]);
    },
    considerations(state) {
      return state.considerations;
    },
    errors(state) {
      return state.errors;
    },
    currentTraineeEvaluation(state) {
      return state.currentTraineeEvaluation;
    },
    results(state) {
      return state.results;
    },
    isLoading(state) {
      return state.loading;
    },
    chronologicData(state) {
      return state.chronologicData;
    }
  },
};
