import StoreHelper from "../helper/store-helper";
import FetchQueue from "../helper/fetch-queue";
import { random, times } from "lodash";

export default {
  namespaced: true,
  state() {
    return {
      /**
       * contains the
       * webssessions which were loaded
       * by GET endpoint of builder
       */
      loadedExercises: null,
      /**
       * contains the exercise which is
       * in creation or in edit
       */
      localExercisesState: null,
      /**
       * server response for last
       * adding exercise action
       */
      exercisesResponseMessages: [],
      /**
       * contains errors
       * for last action
       */
      errors: false,
      /**
       * confirmation flag if exercise
       * has been changed
       */
      exercisesChanged: false,
      /**
       * loading switch
       */
      loading: false,
      /**
       * contains exercises that
       * where added lately - this is
       * not permanent and only valid
       * for the first editing to
       * allow editing the attributes
       */
      latelyAddedExercises: []
    };
  },
  mutations: {
    /**
     * set exercises to lately
     * added state
     * @param {*} state 
     */
    setLatelyAddedExercises(state, payload) {
      state.latelyAddedExercises = [ ...payload ];
    },
    /**
     * add exercises to lately
     * added state
     * @param {*} state 
     */
    addLatelyAddedExercises(state, payload) {
      state.latelyAddedExercises.push(payload)
    },
    /**
     * remove all exercises from
     * lately added state
     * @param {*} state 
     */
    resetLatelyAddedExercise(state) {
      state.latelyAddedExercises = [];
    },
    /**
     * set flag to set exercises as
     * saved for this session in the
     * backend (both edit or create)
     * @param {*} state
     */
     setPersistLocalCourseState(state) {
      state.exercisesChanged = true;
    },
    /**
     * sets the response messages for the
     * last exercise request
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setExercisesResponseMessages(state, payload) {
      state.exercisesResponseMessages = [ ...payload];
    },
    /**
     * sets the exercises
     * object to store
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setLoadedExercises(state, payload) {
      if(payload) {
        state.loadedExercises = [ ...payload ];
      } else {
        state.loadedExercises = [];
      }
      state.loading = false;
    },
    /**
     * method to temporary save
     * the exercise before saving
     * @param {*} state
     * @param {*} payload
     */
    setLocalExercisesState(state, payload) {
      state.exercisesChanged = false;
      state.localExercisesState = [ ...payload ];
    },
    /**
     * add one error to the store
     * @param {*} state 
     * @param {*} payload 
     */
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    /**
     * set errors to the store
     * @param {*} state 
     * @param {*} payload 
     */
    setErrors(state, payload) {
      state.errors = payload;
    },
    /**
     * triggers the confirmation of
     * saving the currently loaded exercise
     * in localExercisesState into
     * the backend for persistence
     * 
     * @param {*} state
     * @param {*} payload
     */
    persistLocalExercisesState(state, payload) {
      state.localExercisesState = null;
      state.latelyAddedExercises = [];
      state.exercisesChanged = true;
    },
    /**
     * clears the state
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    clear(state) {
      state.loadedExercises = [];
      state.latelyAddedExercises = [];
      state.localExercisesState = null;
      state.exercisesChanged = false;
      state.exercisesResponseMessages = null;
      state.errors = null;
      state.loading = false;
    },
    startLoading(state) {
      state.loading = true;
    }
  },
  actions: {
    /**
     * resets the temporary state
     * for latetly added exercises
     * @param {*} context 
     */
    resetTempLatelyAddedExercises(context) {
      context.commit("resetLatelyAddedExercise");
    },
    /**
     * set exercises to lately
     * added state
     * @param {*} state 
     */
    setTempLatelyAddedExercises(context, payload) {
      if(payload) {
        context.commit("setLatelyAddedExercises", payload);
      }
    },
    /**
     * loads a exercise set by
     * the given cid in the payload
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async loadExercisesByCourse(context, payload) {
      if(!payload.cid) {
        context.commit("setLoadedExercises", []);
        return;
      }
      context.commit("startLoading");
      const apiCall = () => {
        return fetch(
          `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/exercises/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
          }
        );
      }
      const response = await FetchQueue.enqueue(apiCall, "loadCourseExercises");
      const responseData = await response.json();
      context.commit("setLoadedExercises", [...responseData]);
    },
    /**
     * updates a exercises from the current
     * local state (localExercisesState)
     * expects payload to contain { cid: Number }
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async updateExercises(context, payload) {
      if (payload && payload.cid > 0) {
        context.commit("startLoading");
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/exercises/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              context.rootGetters["builderExercises/localExercisesState"]
            ),
          });
        };
        const response = await apiCall();
        const data = await response.json();

        // inject pk`s in latelyAddedExercises
        const latelies = context.rootGetters["builderExercises/latelyAddedExercises"];
        /**
         * @todo this is bah..
         * background is: we have to know the latest added
         * exercises, being able to allow changing strat and goal initially
         * otherwise it will be strictly disabled in ready courses
         */
        latelies.map(l => {
          // ich kann die nicht mehr zuordnen, wenn es mehrere gleiche origins gab die zugefügt wurden
          // const d = data.filter(i => i.)
        });
        
        StoreHelper.handleHttpError(response, context);
        if(data && typeof data === "object") {
          context.commit("setPersistLocalCourseState");
          context.commit("setLocalExercisesState", [...data]);
          context.commit("setLoadedExercises", [...data]);
        }
      } else {
        throw new Error("Failed exercise update state action due to wrong payload");
      }
    },
    /**
     * sets temp exerciseobject in creation
     * into store for later persistence
     *
     * @param {*} context
     * @param {*} payload
     */
    async setLocalExercisesState(context, payload) {
      if (payload) {
        context.commit("setLocalExercisesState", payload);
      }
    },
    /**
     * adds list of latest added exercises
     *
     * @param {*} context
     * @param {*} payload
     */
    async addLatelyAddedExerciseState(context, payload) {
      if (payload) {
        context.commit("addLatelyAddedExercises", payload);
      }
    },
    /**
     * action to clear error store
     * 
     * @param {*} context 
     */
    clearErrors(context) {
      context.commit("setErrors", false);
    },
    clearState(context) {
      context.commit("clear");
    }
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    exercisesChanged(state) {
      return state.exercisesChanged;
    },
    exercisesResponseMessages(state) {
      return state.exercisesResponsesMessages;
    },
    errors(state) {
      return state.errors;
    },
    localExercisesState(state) {
      return state.localExercisesState;
    },
    loadedExercises(state) {
      return state.loadedExercises;
    },
    latelyAddedExercises(state) {
      return state.latelyAddedExercises;
    }
  },
};
