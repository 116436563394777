import { isString } from "lodash";

export default {
  handleHttpError(response, context, responseData = null) {
    if (!response.ok) {
      let errorMessage = "";
      // register error

      if (!responseData) {
        context.commit("addError", {
          status: response.status,
          message: response.statusText,
        });
        errorMessage = response.statusText;
      } else {
        if (
          responseData.non_field_errors &&
          responseData.non_field_errors.length
        ) {
          for (const error of responseData.non_field_errors) {
            context.commit("addError", {
              status: response.status,
              message: error,
            });
            errorMessage += error + ", ";
          }
        } else if (responseData.error && isString(responseData.error)) {
          context.commit("addError", {
            status: response.status,
            message: responseData.error,
          });
          errorMessage = responseData.error;
        }
      }

      // report error
      // const error = new Error(errorMessage);
      if (this.$store) {
        this.$store.dispatch("error/report", {
          errorMessage,
          route: window.location.href,
        });
      }

      console.error("Failed while communicating with API");
      console.log(response);
    }

    // redirect when unauthorized
    if (response.status == 401 || (response.status == 500 && response.url.indexOf("/login/welcome") !== -1)) {
      context.dispatch("auth/logout", null, { root: true });
      window.location.href = '/signin';
      throw response;
    }
  },
};
