import StoreHelper from "../helper/store-helper";
import FetchQueue from "../helper/fetch-queue";

export default {
  namespaced: true,
  state() {
    return {
      /**
       * contains the
       * webssessions which were loaded
       * by GET endpoint of builder
       */
      loadedWebsessions: null,
      /**
       * server response for last
       * remove websession action
       */
      removeWebsessionResponse: null,
      /**
       * server response for last
       * adding websession action
       */
      websessionResponseMessages: [],
      /**
       * contains errors
       * for last action
       */
      errors: false,
      /**
       * confirmation flag if websession
       * has been changed
       */
      websessionChanged: false,
      /**
       * confirmation flag if websession
       * has been creatd
       */
      websessionCreated: false,
      /**
       * loading switch
       */
      loading: false,
      /**
       * contains websession id of last
       * created item
       */
      savedWebsessionId: null,
    };
  },
  mutations: {
    /**
     * sets the response messages for the
     * last websession request
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setWebsessionResponseMessages(state, payload) {
      state.websessionResponseMessages = [ ...payload];
    },
    /**
     * sets the response of the last
     * remove user action for websession
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setRemoveWebsessionResponse(state, payload) {
      state.removeWebsessionResponse = payload;
    },
    /**
     * sets the websessions
     * object to store
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setLoadedWebsessions(state, payload) {
      state.loadedWebsessions = [ ...payload ];
      state.websessionChanged = false;
      state.websessionCreated = false;
    },
    /**
     * add one error to the store
     * @param {*} state 
     * @param {*} payload 
     */
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    /**
     * set errors to the store
     * @param {*} state 
     * @param {*} payload 
     */
    setErrors(state, payload) {
      state.errors = payload;
    },
    /**
     * sets the id of the just
     * saved websession
     * 
     * @param {*} state
     * @param {*} payload
     */
    setSavedWebsessionId(state, payload) {
      state.savedWebsessionId = payload;
    },
    /**
     * triggers the confirmation of
     * saving the websession to
     * the backend for persistence
     * 
     * @param {*} state
     * @param {*} payload
     */
    persistLocalWebsessionState(state, payload) {
      state.websessionChanged = payload === "changed" ? true : false;
      state.websessionCreated = payload === "created" ? true : false;
    }
  },
  actions: {
    /**
     * reset response for websession
     * 
     * @param {*} context 
     */
    async resetRemoveWebsessionResponse(context) {
      // reset response switch
      context.commit("setRemoveWebssionResponse", "init");
    },
    /**
     * loads a websession set by
     * the given cid in the payload
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async loadWebsessionsByCourse(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/websessions/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      context.commit("setLoadedWebsessions", responseData);
    },
    /**
     * updates a websession from the payload
     * expects payload to contain { cid: Number, websession }
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async updateWebsession(context, payload) {
      const websessionId = payload.websession?.pk;
      if (payload && payload.cid > 0 && payload.websession) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/websession/${websessionId}/`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              payload.websession
            ),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "updateWebsession");
        StoreHelper.handleHttpError(response, context);
        context.commit("persistLocalWebsessionState", "changed");
      } else {
        throw new Error("Failed websession update state action due to wrong payload");
      }
    },
    /**
     * removes a websession from
     * the given course
     * payload must contain cid for course
     * and wid for websession
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async removeWebsession(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/websession/${payload.wid}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          }
        }
      );
      context.commit("setRemoveWebsessionResponse", response.status);
    },
    /**
     * saves the a new websession
     * to the backend
     * expect payload to have attr
     * `cid` for course id
     * and `websession`
     *
     * @param {*} context
     * @param {*} payload
     */
    async createWebsession(context, payload) {
      if (payload.cid && payload.websession) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/websession/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              payload.websession
            ),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "saveTemplate");
        const responseData = await response.json();
        StoreHelper.handleHttpError(response, context);
        context.commit("persistLocalWebsessionState", "created");
        // state.savedCourseId
        context.commit("setSavedWebsessionId", responseData.pk)
      }
    },
    /**
     * action to clear error store
     * 
     * @param {*} context 
     */
    clearErrors(context) {
      context.commit("setErrors", false);
    },
  },
  getters: {
    savedWebsessionId(state) {
      return state.savedWebsessionId;
    },
    loading(state) {
      return state.loading;
    },
    websessionCreated(state) {
      return state.websessionCreated;
    },
    websessionChanged(state) {
      return state.websessionChanged;
    },
    websessionResponseMessages(state) {
      return state.websessionResponseMessages;
    },
    errors(state) {
      return state.errors;
    },
    removeWebsessionResponse(state) {
      return state.removeWebsessionResponse;
    },
    loadedWebsessions(state) {
      return state.loadedWebsessions;
    }
  },
};
