import StoreHelper from "../helper/store-helper";
import FetchQueue from "../helper/fetch-queue";

export default {
  namespaced: true,
  state() {
    return {
      /**
       * the global available
       * trainers
       */
      trainers: [],
      /**
       * the current courses
       * trainer object
       */
      courseTrainer: {},
      /**
       * server response for last
       * adding trainer action
       */
      courseTrainerResponseMessages: [],
      /**
       * contains errors for last action
       */
      errors: false,
      /**
       * loading switch
       */
      loading: false,
    };
  },
  mutations: {
    /**
     * sets the global
     * certified trainers
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setTrainers(state, payload) {
      if(!payload) {
        state.trainers = [];
      } else {
        state.trainers = [ ...payload ];
      }
    },
    /**
     * sets the response messages for the
     * last trainer post request
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseTrainerResponseMessages(state, payload) {
      state.courseTrainerResponseMessages = [ ...payload];
    },
    /**
     * sets the course-trainer
     * object
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseTrainer(state, payload) {
      if(!payload) {
        state.courseTrainer = {};
      } else {
        state.courseTrainer = { ...payload.trainer };
      }
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    /**
     * returns all system certified
     * trainers that can be selected
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async loadTrainers(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/trainers/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          }
        }
      );
      const responseData = await response.json();
      context.commit("setTrainers", responseData);
    },
    async resetCourseTrainer(context) {
      // reset currently loaded trainer
      context.commit("setCourseTrainer", null);
    },
    /**
     * assigns new trainer or edits the
     * trainer if the course has already one
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async getCourseTrainer(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainer/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          }
        }
      );
      const responseData = await response.json();
      context.commit("setCourseTrainer", responseData);
    },
    /**
     * assigns new trainer or edits the
     * trainer if the course has already one
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async setCourseTrainer(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainer/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            { trainer: payload.trainer }
          ),
        }
      );
      const responseData = await response.json();
      context.commit("setCourseTrainer", responseData);
      context.commit("setCourseTrainerResponseMessages", responseData.messages);
    },
    /**
     * get all trainees of
     * the current course
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async getCourseTrainees(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainees/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          }
        }
      );
      const responseData = await response.json();
      context.commit("setCourseTrainees", responseData);
    },
    /**
     * sets parts or full object of the
     * course object in creation to later
     * pass it to the server by the action
     * `saveCourse`
     * @param {*} context
     * @param {*} payload
     */
    async setLocalCourseState(context, payload) {
      if (payload) {
        context.commit("setLocalCourseState", payload);
      }
    },
    /**
     * sets parts or full object of the
     * template object in creation to later
     * pass it to the server by the action
     * `saveCourse`
     * @param {*} context
     * @param {*} payload
     */
    async setLocalTemplateState(context, payload) {
      if (payload) {
        context.commit("setLocalTemplateState", payload);
      }
    },
    /**
     * action to clear error store
     * 
     * @param {*} context 
     */
    clearErrors(context) {
      context.commit("setErrors", false);
    },
    /**
     * saves the set course data
     * to the backend
     */
    async saveTemplate(context, payload) {
      if (payload) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/template/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(payload),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "saveTemplate");
        StoreHelper.handleHttpError(response, context);
      }
    },
  },
  getters: {
    errors(state) {
      return state.errors;
    },
    trainers(state) {
      return state.trainers;
    },
    courseTrainer(state) {
      return state.courseTrainer;
    },
    courseTrainerResponseMessages(state) {
      return state.courseTrainerResponseMessages;
    }
  },
};
