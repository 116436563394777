export default {
  namespaced: true,
  state() {
    return {
      emailToReset: null,
      passwordResetSuccessfull: null,
      token: null,
      loading: false,
      errors: false,
    };
  },
  mutations: {
    setResetEmail(state, payload) {
      state.emailToReset = payload;
    },
    setPasswordResetSuccessfull(state, payload) {
      state.passwordResetSuccessfull = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    /**
     * starts password reset process
     * expects payload to be string (email)
     */
    async triggerPasswordReset(context, payload) {
      context.commit("setLoading", true);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}password_reset/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: payload }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        // prepare error message for email field
        if (responseData.email && responseData.email.length) {
          for (const error of responseData.email) {
            context.commit("addError", {
              status: "error",
              message: error,
            });
          }
        }
        console.error("Reset process failed");
      }

      const result = responseData.status == "OK";
      localStorage.setItem("setResetEmail", result);
      context.commit("setResetEmail", result);
      context.commit("setLoading", false);
    },

    /**
     * sets a new password
     */
    async confirmPasswordReset(context, payload) {
      context.commit("setLoading", true);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}password_reset/confirm/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        // register error
        if (responseData.status == "notfound" || responseData.status != "ok") {
          if (
            responseData.password != undefined &&
            responseData.password.length
          ) {
            // erros are stuck to fields in this case
            for (const error of responseData.password) {
              context.commit("addError", {
                status: response.status,
                message: error,
              });
            }
          } else {
            context.commit("addError", {
              status: response.status,
              message:
                "Invalid token. We cannot find your password reset request. Maybe the link has expired.",
            });
          }
        }
        console.error("Cannot save new password");
      }

      if (responseData.status == "OK") {
        context.commit("setPasswordResetSuccessfull", true);
      }
      context.commit("setLoading", false);
    },
  },
  getters: {
    emailToReset(state) {
      return state.emailToReset;
    },
    token(state) {
      return state.token;
    },
    passwordResetSuccessfull(state) {
      return state.passwordResetSuccessfull;
    },
    isLoading(state) {
      return state.loading;
    },
    errors(state) {
      return state.errors;
    },
  },
};
