import StoreHelper from "../../helper/store-helper";
import FetchQueue from "../../helper/fetch-queue";

export default {
  namespaced: true,
  state() {
    return {
      editableGaps: null,
      createdExerciseIds:  null,
      solveResponse: null,
      patientChangeResponse: null,
      realInputEnabled: false,
      operationSnapshotResponse: null,
      loading: false,
      errors: false,
    }
  },
  mutations: {
    setEditableGaps(state, payload) {
      state.editableGaps = { ...payload };
    },
    setCreatedIds(state, payload) {
      state.createdExerciseIds = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setSolveResponse(state, payload) {
      state.solveResponse = payload;
    },
    setPatientChangeResponse(state, payload) {
      state.patientChangeResponse = { ...payload};
    },
    setRealInputSwitchState(state, payload) {
      state.realInputEnabled = payload;
    },
    setOperationSnapshotResponse(state, payload) {
      state.operationSnapshotResponse = { ...payload };
    },
    resetStates(state) {
      state.operationSnapshotResponse = null;
      state.createdExerciseIds = null;
      state.patientChangeResponse = null;
      state.solveResponse = null;
      state.realInputEnabled = true;
      state.errors = false;
    }
  },
  actions: {
    /**
     * set latest state of editableGaps
     * in store
     * @param {*} context
     * @param {*} payload
     */
    setEditableGaps(context, payload) {
      context.commit("setEditableGaps", payload);
    },
    /**
     * reset all states to
     * default or empty for a
     * new session
     * @param {*} context
     * @param {*} payload
     */
    resetStates(context,payload) {
      context.commit("resetStates");
    },
    /**
     * sets state for the car switch
     * "real input", which controls to which
     * endpoint the (component based) input
     * is sent
     * @param {*} context 
     * @param {*} payload 
     */
    setRealInputState(context, payload) {
      context.commit("setRealInputSwitchState", payload);
    },
    /**
     * will create a new exercise instance for
     * car that is initited with an empty patient
     * returns instance_id and id
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async initiateInstance(context, payload) {
      if (payload.courseId) {
        context.commit("setLoading", true);
        const apiCall = () => {
          return fetch(
            `${process.env.VUE_APP_CAT_API_URL}car/course/${payload.courseId}/exercise/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "token " + context.rootGetters["auth/token"],
              },
            }
          );
        };
        const response = await FetchQueue.enqueue(apiCall, "create_blank_exercise");
        const responseData = await response.json();

        StoreHelper.handleHttpError(response, context);
        context.commit(
          "setCreatedIds",
          { id: parseInt(responseData.id), instanceId: parseInt(responseData.isntance_id) }
        );
        context.commit("setLoading", false);
      }
    },
    /**
     * sets the patient data
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async setPatient(context, payload) {
      
      if (payload.exerciseId) {
        context.commit("setLoading", true);
        // also set editable gaps to the current values
        context.commit("setEditableGaps", { ...payload.data.gaps });
        
        const apiCall = () => {
          return fetch(
            `${process.env.VUE_APP_CAT_API_URL}car/exercise/${payload.exerciseId}/patient/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "token " + context.rootGetters["auth/token"],
              },
              body: JSON.stringify(payload.data),
            }
          );
        };
        const response = await FetchQueue.enqueue(apiCall, "setPatient");
        const responseData = await response.json();

        StoreHelper.handleHttpError(response, context);
        context.commit(
          "setPatientChangeResponse",
          responseData
        );
        context.commit("setLoading", false);
      }
    },
    /**
     * sets the patients operation
     * state values
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async setOperationStateValues(context, payload) {
      if (payload.exerciseId) {
        context.commit("setLoading", true);
        const apiCall = () => {
          return fetch(
            `${process.env.VUE_APP_CAT_API_URL}car/exercise/${payload.exerciseId}/op_value_set/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "token " + context.rootGetters["auth/token"],
              },
              body: JSON.stringify(payload.data),
            }
          );
        };
        const response = await FetchQueue.enqueue(apiCall, "setOperationState");
        const responseData = await response.json();

        StoreHelper.handleHttpError(response, context);

        // reset post_shift
        responseData.femur.post_shift = 0;

        context.commit(
          "setOperationSnapshotResponse",
          responseData
        );
        context.commit("setLoading", false);
      }
    },
    /**
     * get the patients operation
     * state snapshot data
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async getOperationStateValues(context, payload) {
      context.commit("setLoading", true);
      const apiCall = () => {
        return fetch(
          `${process.env.VUE_APP_CAT_API_URL}car/exercise/${payload.exerciseId}/op_value_set/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            }
          }
        );
      };
      const response = await FetchQueue.enqueue(apiCall, "getOperationState");
      const responseData = await response.json();

      StoreHelper.handleHttpError(response, context);
      context.commit(
        "setOperationSnapshotResponse",
        responseData
      );
      context.commit("setLoading", false);
    },
    /**
     * recalculates values with
     * the backend algorithm
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async solveAlgorithm(context, payload) {
      if (payload.exerciseId) {
        context.commit("setLoading", true);
        const apiCall = () => {
          return fetch(
            `${process.env.VUE_APP_CAT_API_URL}car/exercise/${payload.exerciseId}/solve/`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "token " + context.rootGetters["auth/token"],
              }
            }
          );
        };
        const response = await FetchQueue.enqueue(apiCall, "carSolveAlgorithm");
        const data = await response.json();

        StoreHelper.handleHttpError(response, context);
        context.commit("setLoading", false);
        context.commit("setSolveResponse", data);
      }
    }
  },
  getters: {
    isLoading(state) {
      return state.loading;
    },
    createdExerciseIds(state) {
      return state.createdExerciseIds;
    },
    solveResponse(state) {
      return state.solveResponse;
    },
    patientChangeResponse(state) {
      return state.patientChangeResponse;
    },
    realInputEnabled(state) {
      return state.realInputEnabled;
    },
    operationSnapshotResponse(state) {
      return state.operationSnapshotResponse;
    },
    editableGaps(state) {
      return state.editableGaps;
    }
  }
};
