import StoreHelper from "../helper/store-helper";
import FetchQueue from "../helper/fetch-queue";

export default {
  namespaced: true,
  state() {
    return {
      /**
       * contains the loaded
       * course which were loaded
       * by GET endpoint of builder
       */
      loadedCourse: null,
      /**
       * contains the currently loaded
       * template which were fetched
       * from the backend
       */
      loadedTemplate: null,
      /**
       * contains the initial source
       * exercises to build own courses from
       */
      exercises: [],
      /**
       * contains the exercise details
       * for a specific exercise
       */
      exerciseDetail: null,
      /**
       * contains the available templates
       */
      templates: [],
      /**
       * contains the course which is
       * in creation
       */
      localCourseState: null,
      /**
       * contains the temporary
       * template state which is
       * currently in creation
       */
      localTemplateState: null,
      /**
       * the current courses
       * trainer object
       */
      courseTrainer: {},
      /**
       * the current courses
       * trainees array list
       */
      courseTrainees: [],
      /**
       * server response for last
       * remove user action
       */
      removeTraineesResponse: null,
      /**
       * server response for last
       * adding trainees action
       */
      courseTraineesResponseMessages: [],
      /**
       * server response for last
       * adding trainer action
       */
      courseTrainerResponseMessages: [],
      /**
       * available sponsor buckets with
       * licenses that can be assigned
       */
      availableSponsors: [],
      /**
       * contains errors for last action
       */
      errors: false,
      /**
       * confirmation flag if new course
       * has been created
       */
      courseSaved: false,
      /**
       * confirmation flag if new template
       * has been created
       */
      templateSaved: false,
      /**
       * confirmation flag if template
       * has been changed
       */
      templateChanged: false,
      /**
       * confirmation flag if course
       * has been changed
       */
      courseChanged: false,
      /**
       * confirmation flag if course
       * has been cpied
       */
      courseCopied: false,
      /**
       * contains min max bounds for
       * the exercise filter
       */
      minMaxBounds: null,
      /**
       * toggle for saving the the
       * course exercise configuration
       * as named template in the
       * course create process
       */
      saveNamedTemplate: null,
      /**
       * loading switch
       */
      loading: false,
      /**
       * response indicator for
       * course ready state change
       */
      courseReadySuccess: false,
      /**
       * response indicator for
       * course ready state change
       * in case of error
       */
      courseReadyError: false,
      /**
       * contains the response of the
       * resend  endpoint
       */
      resendResponse: null,
      /**
       * contains the response of the
       * request to confirm a trainees
       * invite confirmation
       */
      traineeInvitationConfirm: false,
      /**
       * contains course id of last
       * created item
       */
       savedCourseId: null,
      /**
       * contains template id of last
       * created item
       */
      savedTemplateId: null,
      /**
       * set course as copied
       */
      courseCopied: false
    };
  },
  mutations: {
    /**
     * sets loading state
     * to indicate status of
     * this store
     */
    setLoading(state, payload) {
      state.loading = payload;
    },

    /**
     * sets the list of available
     * sponsor buckets that are 
     * available to the current acting user
     * @param {*} state 
     * @param {*} payload 
     */
    setAvailableSponsors(state, payload) {
      state.availableSponsors = payload;
    },
    /**
     * sets the response messages for the
     * last trainees post request
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseTraineesResponseMessages(state, payload) {
      state.courseTraineesResponseMessages = [ ...payload];
    },
    /**
     * sets the response messages for the
     * last trainer post request
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseTrainerResponseMessages(state, payload) {
      state.courseTrainerResponseMessages = [ ...payload];
    },
    /**
     * sets the response of the last
     * remove user action for courses
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setRemoveTraineesResponse(state, payload) {
      state.removeTraineesResponse = payload;
    },
    /**
     * sets the course-trainer
     * object
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseTrainer(state, payload) {
      if(!payload) {
        state.courseTrainer = {};
      } else {
        state.courseTrainer = { ...payload.trainer };
      }
    },
    /**
     * sets the course-trainees
     * object
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseTrainees(state, payload) {
      if(payload.trainees) {
        state.courseTrainees = [ ...payload.trainees ];
      }
    },
    /**
     * sets the min max bounds for
     * the available exercise filter
     * @param {*} state
     * @param {*} payload
     */
    setMinMaxBounds(state, payload) {
      state.minMaxBounds = { ...payload };
    },
    /**
     * method to sequencially save
     * the course through the process steps
     * @param {*} state
     * @param {*} payload
     */
    setLocalCourseState(state, payload) {
      state.localCourseState = { ...payload };
    },
    /**
     * method to sequencially save
     * the template through the process steps
     * @param {*} state
     * @param {*} payload
     */
    setLocalTemplateState(state, payload) {
      state.localTemplateState = { ...payload };
    },
    /**
     * set flag to set course as
     * saved for this session in the
     * backend (both edit or create)
     * @param {*} state
     */
    persistLocalCourseState(state) {
      state.courseSaved = true;
      state.localCourseState = null;
    },
    /**
     * set flag to set template as
     * saved for this session in the
     * backend (both edit or create)
     * @param {*} state
     */
    persistLocalTemplateState(state) {
      state.templateSaved = true;
      state.localTemplateState = null;
    },
    /**
     * method to save the as a
     * named template
     * @param {*} state
     * @param {*} payload
     */
    createTemplate(state, payload) {
      state.attendees = payload.coursedetails;
      state.currentCourseDetails = {
        courseName: payload.course_name,
        instructor: payload.instructor,
        websessions: payload.websessions,
      };
    },
    /**
     * method to set the detailed
     * information about an exercise
     * for the course builder
     * @param {*} state
     * @param {*} payload
     */
    setExerciseDetail(state, payload) {
      state.exerciseDetail = payload;
    },
    /**
     * sets all available exercise
     * blueprints for the course builder
     * @param {*} state
     * @param {*} payload
     */
    setExercises(state, payload) {
      if(!payload) {
        payload = [];
      }
      const mutatedPayload = payload.map((i) => ({
        id: i.id,
        title: i.name,
        detail: i.detail,
        uexid: i.uexid,
      }));
      state.exercises = [...mutatedPayload];
    },
    setTemplates(state, payload) {
      state.templates = { ...payload };
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
    setLoadedCourse(state, payload) {
      state.loadedCourse = { ...payload };
    },
    setLoadedTemplate(state, payload) {
      state.loadedTemplate = { ...payload };
    },
    /**
     * payload expected to be boolean
     * and sets the response state of
     * a ready state change request
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseReadySuccess(state, payload) {
      state.courseReadySuccess = payload;
    },
    /**
     * payload expected to be boolean
     * and sets the response state of
     * a ready state change request to
     * error state
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseReadyError(state, payload) {
      state.courseReadyError = payload;
    },
    /**
     * sets response of resend registration/ invitation
     * email endpoint
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setResendResponse(state, payload) {
      state.resendResponse = payload;
    },
    /**
     * set true or false to confirm the
     * confirmation of the users confirmation
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setTraineeInvitationConfirm(state, payload) {
      state.traineeInvitationConfirm = payload;
    },
    /**
     * sets the id of the created course
     * 
     * @param {*} state
     * @param {*} payload
     */
    setCreateCourseId(state, payload) {
      state.savedCourseId = payload;
    },
    /**
     * sets the id of the created template
     * 
     * @param {*} state
     * @param {*} payload
     */
    setCreateTemplateId(state, payload) {
      state.savedTemplateId = payload;
    },
    /**
     * mutation to set the copy confirmation
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCourseCopied(state, payload) {
      state.courseCopied = payload;
    }
  },
  actions: {
    async reset(context) {
      context.commit("setResendResponse", "init");
      context.commit("setCreateTemplateId", null);
      context.commit("setRemoveTraineesResponse", "init");
      context.commit("setLoadedCourse", null);
      context.commit("setErrors", []);
      context.commit("setCourseTrainees", []);
    },
    async resetCourseTrainees(context) {
      context.commit("setErrors", []);
      context.commit("setCourseTrainees", []);
      context.commit("setCourseTraineesResponseMessages", []);
    },
    async resetCourseTrainer(context) {
      // reset currently loaded trainer
      context.commit("setCourseTrainer", null);
    },
    async resetRemoveTraineesResponse(context) {
      // reset response switch
      context.commit("setRemoveTraineesResponse", "init");
    },
    async resetResendResponse(context) {
      // reset response switch
      context.commit("setResendResponse", "init");
    },
    /**
     * sets the confirmation state 
     * for an invite of a trainee
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    async confirmTrainee(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/confirm/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            payload.id
          ),
        }
      );
      const responseData = await response.status;
      if(responseData !== 200 && responseData !== 201) {
        throw new Error("Confirmation failed.");
      } else {
        context.commit("setTraineeInvitationConfirm", responseData == 201 || responseData == 200 ? true : false);
      }
    },
    /**
     * will to a renew of licenses
     * to the given trainees
     * @param {*} context 
     * @param {*} payload 
     */
    async renewTraineesLicense(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.course}/trainees/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            payload.users
          ),
        }
      );
      const responseData = await response.json();
      context.commit("setCourseTrainees", responseData);
      if(!responseData.messages || !responseData.messages.length) {
        responseData.messages = [{ message: "Successfully added" }]
      }
      context.commit("setCourseTraineesResponseMessages", responseData.messages);
    },
    /**
     * resend register mail for selected
     * users
     * payload expects a list of user ids in
     * attribute `users` and the course id
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    async resendRegisterEmail(context, payload) {
      if(payload && payload.users?.length && payload.course) {
        // map id array to resend endpoints format
        const resendArray = payload.users.map(id => {
          return {
            pk: id,
            course_invite: false,
            register: true,
          };
        });
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.course}/resend_invite/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              resendArray
            ),
          }
        );
        const responseData = await response.status;
        context.commit("setResendResponse", responseData == 200 ? true : false);
      } else {
        throw new Error("Failed resend register E-Mail due to wrong payload");
      }
    },
    /**
     * resend course invitation for selected
     * users
     * payload expects a list of user ids in
     * attribute `users` and the course id to reinvite
     * on in attribute `course` 
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    async resendInvitationEmail(context, payload) {
      if(payload && payload.users?.length && payload.course) {
        // map id array to resend endpoints format
        const resendArray = payload.users.map(id => {
          return {
            pk: id,
            course_invite: true,
            register: false,
          };
        });
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.course}/resend_invite/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              resendArray
            ),
          }
        );
        const responseData = await response.status;
        context.commit("setResendResponse", responseData == 200 ? true : false);
      } else {
        throw new Error("Failed resend course invitation E-Mail due to wrong payload");
      }
    },

    /**
     *
     * will set the course state to ready
     * by requesting the ready endpoint of
     * the backend
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async setCourseStateReady(context, payload) {
      context.commit("setCourseReadySuccess", false);
      context.commit("setCourseReadyError", false);
      context.commit("setErrors", []);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/ready/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      if(response.status !== 201 && response.status !== 200) {
        const responseData = await response.json();
        // set response error to the errors
        context.commit("setCourseReadyError", true);
        switch(responseData.name) {
          case "LICENCE_DURATION":
            context.commit("setErrors", [
              {
                title: "Insufficent Trainee licenses",
                message: "Some of your Trainees` licenses are not valid for the duration of your course. Please go to `Invite Trainees` and confirm the Trainees.",
                code: responseData.name
              }
            ]);
            break;
          default:
            context.commit("setErrors", [
              {
                title: "Something went wrong",
                message: "Sorry, this should had happened. Please try again and contact our support if you still experience problems."
              }
            ]);
        }
      } else {
        context.commit("setCourseReadySuccess", true);
      }
    },
    /**
     * loads a course with the given cid
     * in the payload
     * @param {*} context 
     * @param {*} payload 
     */
    async loadCourse(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      context.commit("setLoadedCourse", responseData);
    },
    /**
     * updates a course with all its
     * attributes by taking the updateCourse
     * state property and passing it to the
     * backend with the decent course id
     * expects payload to contain { cid: Number }
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async updateCourse(context, payload) {
      if (payload && payload.cid > 0) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              context.rootGetters["coursebuilder/localCourseState"]
            ),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "localCourseState");
        if(response.status == 200) {
          const course = await response.json();
          StoreHelper.handleHttpError(response, context);

          context.commit("persistLocalCourseState");
          if(course) {
            context.commit("setLoadedCourse", course);
          }
        } else {
          /**
           * @todo
           */
        }
      } else {
        throw new Error("Failed course update state action due to wrong payload");
      }
    },
    /**
     * returns a list of available
     * sponsor buckets that can be
     * used to assign new users
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async getSponsorList(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/available_sponsors/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      if(response.status === 200) {
        const responseData = await response.json();
        context.commit("setAvailableSponsors", responseData);
      }
    },
    /**
     * assigns new trainer or edits the
     * trainer if the course has already one
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async getCourseTrainer(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainer/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          }
        }
      );
      const responseData = await response.json();
      context.commit("setCourseTrainer", responseData);
    },
    /**
     * assigns new trainer or edits the
     * trainer if the course has already one
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async setCourseTrainer(context, payload) {
      if(payload.cid) {
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainer/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              { trainer: payload.trainer }
            ),
          }
        );
        const responseData = await response.json();
        context.commit("setCourseTrainer", responseData);
        context.commit("setCourseTrainerResponseMessages", responseData.messages);
      }
    },
    /**
     * assigns a new trainee to the
     * the current course and replaces
     * existing
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async setCourseTrainees(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainees/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            payload.users
          ),
        }
      );
      const responseData = await response.json();
      context.commit("setCourseTrainees", responseData);
      if(!responseData.messages || !responseData.messages.length) {
        responseData.messages = [{ message: "Successfully added" }]
      }
      context.commit("setCourseTraineesResponseMessages", responseData.messages);
    },
    /**
     * removes a trainee from
     * the current course
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async removeCourseTrainees(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainees/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            payload.users
          ),
        }
      );
      context.commit("setRemoveTraineesResponse", response.status);
    },
    /**
     * get all trainees of
     * the current course
     * 
     * @param {*} context 
     * @param {*} payloads 
     */
    async getCourseTrainees(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/trainees/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          }
        }
      );
      const responseData = await response.json();
      context.commit("setCourseTrainees", responseData);
    },
    /**
     * will remove a user or users
     * by given ids e.g. users: [{id: 1}] of the
     * given course id
     * expect payload to contain cid, and users
     * property
     * 
     * @deprecated
     * @param {*} context 
     * @param {*} payload 
     */
    async removeAssignedUserFromCouse(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/add_users_to_course/${payload.cid}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
          body: JSON.stringify(
            payload.users
          ),
        }
      );
      const responseData = await response.status;
      context.commit("setRemoveTraineesResponse", responseData);
    },
    /**
     * load the users of a course in
     * the context of course editation
     * 
     * @deprecated
     * @param {*} context 
     * @param {*} payload 
     */
    async loadCourseAssignedUsers(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/add_users_to_course/${payload.cid}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      context.commit("setCourseAssignedUsers", responseData);
    },
    /**
     * sets parts or full object of the
     * course object in creation to later
     * pass it to the server by the action
     * `saveCourse`
     * @param {*} context
     * @param {*} payload
     */
    async setLocalCourseState(context, payload) {
      if (payload) {
        context.commit("setLocalCourseState", payload);
      }
    },
    /**
     * sets parts or full object of the
     * template object in creation to later
     * pass it to the server by the action
     * `saveCourse`
     * @param {*} context
     * @param {*} payload
     */
    async setLocalTemplateState(context, payload) {
      if (payload) {
        context.commit("setLocalTemplateState", payload);
      }
    },
    /**
     * load basic exercises for
     * the custom course selection
     */
    async loadExercises(context, payload) {
      context.commit("setLoading", true);

      // prepare url with query params
      let basePath = process.env.VUE_APP_CAT_API_URL;
      if(basePath.indexOf('http') === -1) {
        basePath = window.location.origin + process.env.VUE_APP_CAT_API_URL;
      }
      const url = new URL(
        `${basePath}builder/sources/exercise/`
      );
      const filter = payload.filter;
      if(filter && Object.keys(filter).length) {
        for (let k of Object.keys(filter)) {
          // add min filter param
          if (payload.switches[k]) {
            url.searchParams.append(`${k}_from`, filter[k].min);
            // add max filter param
            url.searchParams.append(`${k}_to`, filter[k].max);
          }
        }
      }
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + context.rootGetters["auth/token"],
        },
      });
      const responseData = await response.json();
      if(response.status === 200) {
        context.commit("setExercises", responseData);
      } else {
        context.commit("setExercises", []);
      }
      context.commit("setLoading", false);
    },
    /**
     * load basic exercises for
     * the custom course selection
     */
    async loadExerciseDetail(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/sources/detail/${payload.id}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      context.commit("setExerciseDetail", responseData);
    },
    /**
     * load min and max values for the
     * available exercise filter params
     */
    async loadFilterMinMax(context) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/sources/min_max/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      context.commit("setMinMaxBounds", responseData);
    },
    /**
     * saves the set course data
     * to the backend
     */
    async saveCourse(context, payload) {
      if (payload) {
        context.commit("setCreateCourseId", false);
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              payload
            ),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "saveTemplate");
        const responseData = await response.json();
        StoreHelper.handleHttpError(response, context);
        context.commit("persistLocalCourseState");
        context.commit("setCreateCourseId", responseData.pk)
      
      }
    },
    /**
     * manually set course as copied
     * state
     * @param {*} context 
     * @param {*} payload 
     */
    setCourseCopied(context, payload) {
      context.commit("setCourseCopied", payload);
    },
    /**
     * copies a new course into a new template
     */
    async copyTemplate(context, payload) {
      context.commit("setErrors", false);
      if (payload && payload.cid && payload.name) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/template/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              { name: payload.name }
            ),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "createTemplate");
        if(response.status == 404) {
          context.commit("setErrors", [
            { message: "Sorry, you are not the author. You are not allowed to copy the course." }
          ])
          StoreHelper.handleHttpError(response, context);
          context.commit("setCourseCopied", false);
          context.commit("setCreateTemplateId", null)
        } else {
          const responseData = await response.json();
          context.commit("persistLocalTemplateState");
          context.commit("setCourseCopied", true);
          context.commit("setCreateTemplateId", responseData.pk)
        }
      }
    },
    /**
     * createse a new course template
     * in the backend
     */
    async createTemplate(context, payload) {
      if (payload) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/template/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              context.rootGetters["coursebuilder/localTemplateState"]
            ),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "createTemplate");
        const responseData = await response.json();
        StoreHelper.handleHttpError(response, context);
        context.commit("persistLocalTemplateState");
        context.commit("setCreateTemplateId", responseData.pk)
      }
    },
    /**
     * updates a template with all its
     * attributes by taking the updateCourse
     * state property and passing it to the
     * backend with the decent course id
     * expects payload to contain { cid: Number }
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async updateTemplate(context, payload) {
      if (payload && payload.cid > 0 && payload.tid > 0) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/template/${payload.tid}`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              context.rootGetters["coursebuilder/localTemplateState"]
            ),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "updateTemplate");
        StoreHelper.handleHttpError(response, context);
      } else {
        throw new Error("Failed course update state action due to wrong payload");
      }
    },
    /**
     * load templates
     */
    async loadTemplates(context, payload) {
      // prepare url with query params
      let basePath = process.env.VUE_APP_CAT_API_URL;
      if(basePath.indexOf('http') === -1) {
        basePath = window.location.origin + process.env.VUE_APP_CAT_API_URL;
      }
      const url = new URL(
        `${basePath}builder/course/${payload.cid}/templates/`
      );
      const filter = payload.filter;
      for (let k of Object.keys(filter)) {
        // add filter param
        if (payload.switches[k]) {
          url.searchParams.append(k, filter[k]);
        }
      }
      const response = await fetch(
        url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      StoreHelper.handleHttpError(response, context);
      context.commit("setTemplates", responseData);
    },
    /**
     * loads a single template with the given tid
     * in the payload (currently filters from the
     * only available templates endpoint)
     * @param {*} context 
     * @param {*} payload 
     */
    async loadTemplate(context, payload) {
      if(!context.templates) {
        // await context.dispatch("loadTemplates");
      }
      // filter the given tid
      // const template = context.templates.filter(item => item.id === payload.tid);
      // context.commit("setLoadedTemplate", template);
    },
    /**
     * action to clear error store
     * 
     * @param {*} context 
     */
    clearErrors(context) {
      context.commit("setErrors", false);
    },
    /**
     * saves the set course data
     * to the backend
     */
    async saveTemplate(context, payload) {
      if (payload) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/template/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(payload),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "saveTemplate");
        StoreHelper.handleHttpError(response, context);

        // context.commit("setTemplateSaved");
      }
    },
  },
  getters: {
    exercises(state) {
      return state.exercises;
    },
    templates(state) {
      return state.templates;
    },
    exerciseDetail(state) {
      return state.exerciseDetail;
    },
    localCourseState(state) {
      return state.localCourseState;
    },
    localTemplateState(state) {
      return state.localTemplateState;
    },
    errors(state) {
      return state.errors;
    },
    courseSaved(state) {
      return state.courseSaved;
    },
    minMaxBounds(state) {
      return state.minMaxBounds;
    },
    courseTrainer(state) {
      return state.courseTrainer;
    },
    courseTrainees(state) {
      return state.courseTrainees;
    },
    courseTraineesResponseMessages(state) {
      return state.courseTraineesResponseMessages;
    },
    courseTrainerResponseMessages(state) {
      return state.courseTrainerResponseMessages;
    },
    removeTraineesResponse(state) {
      return state.removeTraineesResponse;
    },
    availableSponsors(state) {
      return state.availableSponsors;
    },
    loadedCourse(state) {
      return state.loadedCourse;
    },
    loadedTemplate(state) {
      return state.loadedTemplate;
    },
    courseReadySuccess(state) {
      return state.courseReadySuccess;
    },
    courseReadyError(state) {
      return state.courseReadyError;
    },
    resendResponse(state) {
      return state.resendResponse;
    },
    traineeInvitationConfirm(state) {
      return state.traineeInvitationConfirm;
    },
    savedCourseId(state) {
      return state.savedCourseId;
    },
    savedTemplateId(state) {
      return state.savedTemplateId
    },
    courseCopied(state) {
      return state.courseCopied;
    }
  },
};
