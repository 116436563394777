import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index.js";

// lazy load the router views
const TheAuthentication = () => import("../views/TheAuthentication.vue");
const TheProfile = () => import("../views/TheProfile.vue");
const TheExercise = () => import("../views/TheExercise.vue");
const TheEvaluation = () => import("../views/TheEvaluation.vue");
const TheCourses = () => import("../views/TheCourses.vue");
const CourseDetail = () => import("../views/CourseDetail.vue");
const PageNotFound = () => import("../views/PageNotFound.vue");
const TheComparison = () => import("../views/TheComparison.vue");
const EvaluationWorkflow = () => import("../views/WorkflowEvaluation.vue");
const TrainerEvaluation = () => import("../views/TrainerEvaluation.vue");
const CourseConfirmAttendance = () => import("../views/CourseConfirmAttendance.vue");
const EditCourse = () => import("../views/EditCourse.vue");
const SessionExpired = () => import("../views/SessionExpired.vue");
const TraineesManager = () => import("../views/SponsorViews/TraineesManager.vue");
const TheSignout = () => import("../views/TheSignout.vue");
const CarTask = () => import("../views/TaskViews/CarView.vue");
const CatTask = () => import("../views/TaskViews/CatView.vue");


const routes = [
  {
    path: "/",
    name: "Authentication",
    component: TheAuthentication,
    meta: { authRequired: false, unauthedOnly: true },
  },
  {
    path: "/signin/:signUpInvalid?",
    name: "SignIn",
    component: TheAuthentication,
    meta: { authRequired: false, unauthedOnly: true },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: TheAuthentication,
    meta: { authRequired: false, unauthedOnly: true },
  },
  {
    path: "/signup/:activateToken",
    name: "SignUpLink",
    component: TheAuthentication,
    meta: { authRequired: false, unauthedOnly: true },
  },
  {
    path: "/password-reset/:resetToken",
    name: "PasswordReset",
    component: TheAuthentication,
    meta: { authRequired: false, unauthedOnly: true },
  },
  {
    path: "/course/:cid/tasks",
    name: "Task",
    component: CatTask,
    meta: { authRequired: true },
  },
  {
    path: "/course/:cid/surgeries",
    name: "Surgery",
    component: CarTask,
    meta: { authRequired: true },
  },
  {
    path: "/trainee-mode/tasks/:cid",
    name: "TraineeModeTask",
    component: CatTask,
    meta: { authRequired: true, supervisorOnly: true },
  },
  {
    path: "/trainee-mode/surgeries/:cid",
    name: "TraineeModeSurgery",
    component: CarTask,
    meta: { authRequired: true, supervisorOnly: true },
  },
  {
    path: "/trainees-comparison/:uid/course/:cid",
    name: "TraineesComparison",
    component: TheComparison,
    meta: { authRequired: true, supervisorOnly: true },
  },
  {
    path: "/course/:courseId/exercise/:exerciseId/i/:index/:id?",
    name: "Exercise",
    component: TheExercise,
    meta: { authRequired: true },
  },
  {
    path: "/course/:courseId/evaluation/:id",
    name: "Evaluation",
    component: TheEvaluation,
    meta: { authRequired: true },
  },
  {
    path: "/course/:courseId/workflow-evaluation/:id",
    name: "EvaluationWorkflow",
    component: EvaluationWorkflow,
    meta: { authRequired: true }
  },
  {
    path: "/course/:courseId/comparison/:id",
    name: "Comparison",
    component: TheComparison,
    meta: { authRequired: true },
  },
  {
    path: "/course/:courseId/trainees-comparison/:uid/:id",
    name: "TraineesComparison",
    component: TheComparison,
    meta: { authRequired: true },
  },
  {
    path: "/trainees-results/:eid/trainees-evaluation/:uid/course/:cid",
    name: "TrainerEvaluation",
    component: TrainerEvaluation,
    meta: { authRequired: true, supervisorOnly: true },
  },
  {
    path: "/courses",
    name: "Courses",
    component: TheCourses,
    meta: { authRequired: true, supervisorOnly: true },
  },
  {
    path: "/course-detail/:id",
    name: "CourseDetails",
    component: CourseDetail,
    meta: { authRequired: true, supervisorOnly: false },
  },
  {
    path: "/template-detail/:tid/course/:id",
    name: "TemplateDetails",
    component: CourseDetail,
    meta: { authRequired: true, supervisorOnly: false },
  },
  // endpiont to confirm attendance of a participant
  {
    path: "/course-confirm-attendance/:id",
    name: "CourseConfirmAttendance",
    component: CourseConfirmAttendance,
    meta: { authRequired: false, unauthedOnly: false },
  },
  {
    path: "/edit-course/:id",
    name: "EditCourse",
    component: EditCourse,
    meta: { authRequired: true, supervisorOnly: false },
  },
  {
    path: "/create-course",
    name: "CreateCourse",
    component: EditCourse,
    meta: { authRequired: true, supervisorOnly: true },
  },
  {
    path: "/copy-course/:id/name/:name/",
    name: "CopyCourse",
    component: EditCourse,
    meta: { authRequired: true, supervisorOnly: true },
  },
  {
    path: "/create-template",
    name: "CreateTemplate",
    component: EditCourse,
    meta: { authRequired: true, supervisorOnly: false },
  },
  {
    path: "/edit-template/:tid/course/:id", // this id has to be the course id
    name: "EditTemplate",
    component: EditCourse,
    meta: { authRequired: true, supervisorOnly: false },
  },
  {
    path: "/manage-trainees/:id",
    name: "ManageTrainees",
    component: TraineesManager,
    meta: { authRequired: true, sponsorOnly: true, },
  },
  // manage courses view for sponsors
  {
    path: "/profile",
    name: "Profile",
    component: TheProfile,
    meta: { authRequired: true },
  },
  {
    path: "/signout",
    name: "Signout",
    component: TheSignout,
    meta: { authRequired: false },
  },
  {
    path: "/session-closed",
    name: "SessionExpired",
    component: SessionExpired,
    meta: { authRequired: false, unauthedOnly: true },
  },
  {
    path: "/login-as",
    name: "LoginAs",
    component: TheAuthentication,
    meta: { authRequired: false },
  },
  // catch all other by a pregmatch
  { path: "/:pathMatch(.*)*", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// set navigation guard to secure locked routes
router.beforeEach((to, from, next) => {
  store.dispatch("auth/loadMe").then((response) => {
    const homeRoute = "/courses";

    // redirect user if the backend forces redirection
    // admins are not allowed to use the frontend at all
    if (
      response.user.redirect &&
      response.user.redirect !== "" &&
      to.name !== "Courses" &&
      to.name !== "PasswordReset" &&
      to.name !== "Authentication" &&
      to.name !== "CourseConfirmAttendance" &&
      to.name !== "SignUpLink" &&
      to.name !== "SignUp" &&
      to.name !== "Signout"
    ) {
      next(homeRoute);
      return;
    } else if (
      to.meta.unauthedOnly &&
      store.getters["auth/token"] &&
      !store.getters["auth/isProvisionalAuthenticated"]
    ) {
      // route does not require auth
      // for users that are logged in BUT not if provisionally
      next(homeRoute);
      return;
    } else if (
      // route do require auth and
      // for user that have no token (guests)
      to.meta.authRequired &&
      !store.getters["auth/token"]
    ) {
      next("/");
      return;
    } else if (
      to.meta.authRequired &&
      store.getters["auth/token"] &&
      store.getters["auth/isProvisionalAuthenticated"]
    ) {
      // route do require auth and
      // for user that have a token but are provisionally authenticated
      next("/");
      return;
    } else {
      next(true);
      return;
    }
  });
});

export default router;
