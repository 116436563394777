import StoreHelper from "../helper/store-helper";

export default {
  namespaced: true,
  state() {
    return {
      loading: false,
      errors: false,
      comparison: false,
    };
  },
  mutations: {
    setComparison(state, payload) {
      state.comparison = payload.response;
    },
    clearComparison(state) {
      state.exerciseInstances = {};
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    /**
     * loads the workflow comparison
     * for a given course exercise id pair
     *
     * @param {*} context
     * @param {string} payload
     */
    async loadComparison(context, payload) {
      context.commit("setLoading", true);
      const gk = payload.gk ? payload.gk : 0;
      const id = payload.id ? payload.id : 0;
      context.commit("clearComparison");

      fetch(
        `${process.env.VUE_APP_CAT_API_URL}exercise/compare_workflow/${id}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      )
        .then((response) => {
          if (response.ok) {
            response.json().then((responseData) => {
              StoreHelper.handleHttpError(response, context);
              context.commit("setComparison", {
                id,
                response: responseData,
                payload: payload,
              });
            });
          } else if (response.status >= 500) {
            context.commit("setComparison", {
              id,
              response: false,
              payload: payload,
            });
          }
        })
        .catch(() => {
          context.commit("setComparison", {
            id,
            response: false,
            payload: payload,
          });
        });
      context.commit("setLoading", false);
    },
  },
  getters: {
    errors(state) {
      return state.errors;
    },
    isLoading(state) {
      return state.loading;
    },
    comparison(state) {
      return state.comparison;
    }
  },
};
