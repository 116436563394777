import StoreHelper from "../helper/store-helper";
import FetchQueue from "../helper/fetch-queue";

export default {
  namespaced: true,
  state() {
    return {
      /**
       * contains the
       * template which is loaded
       * by GET endpoint of builder
       */
      loadedTemplate: null,
      /**
       * server response for last
       * adding template action
       */
      templateResponseMessages: [],
      /**
       * contains errors
       * for last action
       */
      errors: false,
      /**
       * confirmation flag if template
       * has been changed
       */
      templateChanged: false,
      /**
       * confirmation flag if template
       * has been creatd
       */
      templateCreated: false,
      /**
       * loading switch
       */
      loading: false,
      /**
       * contains template id of last
       * created item
       */
      savedTemplateId: null,
      /**
       * contains course id of last
       * created template
       */
      savedRelatedCourseId: null,
      /**
       * contains the selectable temlates
       * for the authed user to create
       * a new course with
       */
      selectableTemplates: [],
    };
  },
  mutations: {
    /**
     * set flag to set template as
     * saved for this session in the
     * backend (both edit or create)
     * @param {*} state
     */
    persistLocalTemplateState(state) {
      state.templateChanged = true;
    },
    /**
     * sets all available, selectable
     * templates which are available
     * to create a new course 
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setSelectableTemplates(state, payload) {
      state.selectableTemplates = [ ...payload ];
    },
    /**
     * sets the response messages for the
     * last template request
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setTemplateResponseMessages(state, payload) {
      state.templateResponseMessages = [ ...payload];
    },
    /**
     * sets the templates
     * object to store
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setLoadedTemplate(state, payload) {
      state.loadedTemplate = { ...payload };
      state.templateChanged = false;
      state.templateCreated = false;
    },
    /**
     * add one error to the store
     * @param {*} state 
     * @param {*} payload 
     */
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      }
    },
    /**
     * set errors to the store
     * @param {*} state 
     * @param {*} payload 
     */
    setErrors(state, payload) {
      state.errors = payload;
    },
    /**
     * sets the id of the just
     * saved template
     * 
     * @param {*} state
     * @param {*} payload
     */
    setSavedTemplateId(state, payload) {
      state.savedTemplateId = payload;
    },
    /**
     * sets the course id of the just
     * saved template
     * 
     * @param {*} state
     * @param {*} payload
     */
    setSavedRelatedCourseId(state, payload) {
      state.savedRelatedCourseId = payload;
    },
    /**
     * sets one currently viewing
     * template 
     * 
     * @param {*} state 
     * @param {*} payload 
     */
    setCurrentTemplate(state, payload) {
      state.currentTemplate = payload;
    }
  },
  actions: {
    /**
     * sets the loaded template to bring it
     * from context of /api/course to /api/builder
     * (specifically set in courses and then read
     * in edit template)
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    setLoadedTemplate(context, payload) {
      context.commit("setLoadedTemplate", payload);
    },
    /**
     * loads a specific template by id
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async loadTemplateById(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/template/${payload.tid}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      context.commit("setLoadedTemplate", responseData);
    },
    /**
     * loads a template set by
     * the given cid in the payload
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async loadTemplatesByCourse(context, payload) {
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}builder/course/${payload.cid}/templates/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.rootGetters["auth/token"],
          },
        }
      );
      const responseData = await response.json();
      context.commit("setSelectableTemplates", responseData);
    },
    /**
     * updates a template from the payload
     * expects payload to contain { cid: Number, template }
     * 
     * @param {*} context 
     * @param {*} payload 
     */
    async updateTemplate(context, payload) {
      context.commit("setSavedTemplateId", null);
      context.commit("setSavedRelatedCourseId", null);
      const templateId = payload.tid;
      if (payload && payload.template) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/template/${templateId}/`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              payload.template
            ),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "updateTemplate");
        StoreHelper.handleHttpError(response, context);
        const responseData = await response.json();
        context.commit("persistLocalTemplateState", "changed");
        context.commit("setSavedTemplateId", responseData.pk)
        context.commit("setSavedRelatedCourseId", responseData.origin)
      } else {
        throw new Error("Failed template update state action due to wrong payload");
      }
    },
    /**
     * saves the a new template
     * to the backend
     * expect payload to have attr
     * `template`
     *
     * @param {*} context
     * @param {*} payload
     */
    async createTemplate(context, payload) {
      context.commit("setSavedTemplateId", null);
      context.commit("setSavedRelatedCourseId", null);
      if (payload.template) {
        const apiCall = () => {
          return fetch(`${process.env.VUE_APP_CAT_API_URL}builder/course/template/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.rootGetters["auth/token"],
            },
            body: JSON.stringify(
              payload.template
            ),
          });
        };
        const response = await FetchQueue.enqueue(apiCall, "saveTemplate");
        const responseData = await response.json();
        StoreHelper.handleHttpError(response, context);
        context.commit("persistLocalTemplateState", "created");
        context.commit("setSavedTemplateId", responseData.pk)
        context.commit("setSavedRelatedCourseId", responseData.origin)
      }
    },
    /**
     * action to clear error store
     * 
     * @param {*} context 
     */
    clearErrors(context) {
      context.commit("setErrors", false);
    },
  },
  getters: {
    savedTemplateId(state) {
      return state.savedTemplateId;
    },
    savedRelatedCourseId(state) {
      return state.savedRelatedCourseId;
    },
    loading(state) {
      return state.loading;
    },
    templateCreated(state) {
      return state.templateCreated;
    },
    templateChanged(state) {
      return state.templateChanged;
    },
    templateResponseMessages(state) {
      return state.templateResponseMessages;
    },
    errors(state) {
      return state.errors;
    },
    loadedTemplate(state) {
      return state.loadedTemplate;
    },
    selectableTemplates(state) {
      return state.selectableTemplates;
    }
  },
};
