import StoreHelper from "../helper/store-helper";
import * as moment from "moment";

const isUserDataValid = function (userData) {
  if (userData && userData.createdAt) {
    // time in sec to cache user data
    const cacheTime = process.env.VUE_APP_CACHE_USER_DATA_SEC;
    const cacheTill = moment(userData.createdAt);
    const now = moment();
    if (cacheTill.add(parseInt(cacheTime), "seconds").isBefore(now)) {
      return false;
    }
    return true;
  }
  return false;
};

export default {
  namespaced: true,
  state() {
    return {
      loggedInUser: null,
      token: null,
      loading: false,
      errors: false,
    };
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setUser(state, payload) {
      state.loggedInUser = payload;
    },
    invalidateAuthentication(state) {
      state.loggedInUser = null;
      state.token = null;
    },
    addError(state, payload) {
      if (!state.errors) {
        state.errors = [
          {
            status: payload.status,
            message: payload.message,
          },
        ];
      } else {
        state.errors.push({
          status: payload.status,
          message: payload.message,
        });
      }
    },
    setErrors(state, payload) {
      state.errors = payload;
    },
  },
  actions: {
    /**
     * injects a token directly
     * @param {*} context 
     * @param {*} payload 
     */
    async injectToken(context, payload) {
      localStorage.setItem("token", payload.token);
      localStorage.setItem("provisionalAuth", JSON.stringify(false));
      context.commit("setToken", payload.token);
      context.commit("setLoading", false);
    },
    async login(context, payload) {
      context.commit("setLoading", true);
      context.commit("setErrors", []);

      // save token
      if (payload.provisional) {
        // mark the authentication as provisional to
        // force the user to change the password first
        localStorage.setItem("provisionalAuth", JSON.stringify(true));
        localStorage.setItem("token", payload.password);
        context.commit("setToken", payload.password);
      } else {
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}login/auth/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        const responseData = await response.json();
        StoreHelper.handleHttpError(response, context, responseData);
        if (response.status == 200) {
          localStorage.setItem("provisionalAuth", JSON.stringify(false));
          localStorage.setItem("token", responseData.token);
          context.commit("setToken", responseData.token);
        }
      }

      context.commit("setLoading", false);
    },
    checkLogin(context) {
      context.commit("setLoading", true);
      const token = localStorage.getItem("token");
      if (token) {
        context.commit("setToken", token);
        context.commit("setLoading", false);
      }
    },
    logout(context) {
      /**
       * clear token in storage but preserve
       * the "actionAfterLogin" param
       */
      const actionAfterLogin = localStorage.getItem("actionAfterLogin");
      localStorage.clear();
      localStorage.setItem("actionAfterLogin", actionAfterLogin);
      
      context.commit("invalidateAuthentication");
      // clear history state
      context.commit('activity/setHistory', [], { root: true });
      // clear course store state
      context.commit('course/clear', null, { root: true });
    },
    /**
     * save a user
     * payload should valid user data
     */
    async saveUser(context, payload) {
      context.commit("setLoading", true);
      context.commit("setErrors", []);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}login/welcome/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.getters.token,
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();
      StoreHelper.handleHttpError(response, context, responseData);

      // change token accordingly
      if (response.status == 200) {
        localStorage.setItem("token", responseData.token);
        context.commit("setToken", responseData.token);

        // reset the first set provisiionlAuth if the first time save is successfull
        localStorage.setItem("provisionalAuth", JSON.stringify(false));
      }
      context.commit("setLoading", false);
      return true;
    },
    /**
     * change a user
     */
    async changeUser(context, payload) {
      context.commit("setLoading", true);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}login/user_update/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.getters.token,
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();
      StoreHelper.handleHttpError(response, context, responseData);
      // invalidate storage
      localStorage.removeItem("userData");
      context.commit("setUser", responseData);
      // reset the first set provisiionlAuth if the first time save is successfull
      localStorage.setItem("provisionalAuth", JSON.stringify(false));
      context.commit("setLoading", false);
    },
    /**
     * change users password
     */
    async changePassword(context, payload) {
      context.commit("setLoading", true);
      const response = await fetch(
        `${process.env.VUE_APP_CAT_API_URL}login/pw_update/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "token " + context.getters.token,
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();
      StoreHelper.handleHttpError(response, context, responseData);

      // change token accordingly
      if (response.status == 200) {
        localStorage.setItem("token", responseData.token);
        context.commit("setToken", responseData.token);
        // reset the first set provisiionlAuth if the first time save is successfull
        localStorage.setItem("provisionalAuth", JSON.stringify(false));
      }
      context.commit("setLoading", false);
    },
    async loadMe(context, payload) {
      context.commit("setLoading", true);

      const persistedToken = localStorage.getItem("token");
      let responseData = JSON.parse(localStorage.getItem("userData"));
      const storageDataVersion = JSON.parse(localStorage.getItem("version"));
      if (
        (persistedToken &&
        (!responseData ||
        process.env.VUE_APP_VERSION !== storageDataVersion ||
        !isUserDataValid(responseData))) || payload?.forceRefresh
      ) {
        // for invalid user data - make a new request
        const response = await fetch(
          `${process.env.VUE_APP_CAT_API_URL}login/welcome/`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + context.getters.token,
            },
          }
        );
        StoreHelper.handleHttpError(response, context, responseData);

        responseData = await response.json();
        responseData.createdAt = moment().format();
        if (responseData?.profile?.registered) {
          // only cache registered user
          localStorage.setItem("userData", JSON.stringify(responseData));
        }
      }

      // load the options attributes
      context.dispatch("options/loadOptions", null, { root: true });

      // mark the authentication as provisional to
      // force the user to change the password first
      if (responseData && responseData.profile) {
        if (!responseData.profile.registered) {
          localStorage.setItem("provisionalAuth", JSON.stringify(true));
        }
      }

      if(!responseData && !persistedToken) {
        responseData = { detail: "Invalid token." };
      }

      context.commit("setUser", responseData);
      context.commit("setLoading", false);
      return {
        user: responseData,
      };
    },
    invalidateSignUp(context) {
      localStorage.setItem("provisionalAuth", JSON.stringify(false));
      localStorage.removeItem("token");
    }
  },
  getters: {
    me(state) {
      return state.loggedInUser;
    },
    token(state) {
      return state.token;
    },
    errors(state) {
      return state.errors;
    },
    isAuthenticated(state) {
      return !!state.token;
    },
    isProvisionalAuthenticated() {
      return JSON.parse(localStorage.getItem("provisionalAuth"));
    },
    isSupervisor(state) {
      let supervisor = false;
      let user = state.loggedInUser;
      if (user && user.groups) {
        for (const group of user.groups) {
          if (group.name == "Supervisor") {
            supervisor = true;
            return;
          }
        }
      }
      localStorage.setItem("isSupervisor", JSON.stringify(supervisor));
      return supervisor;
    },
    userFullname(state) {
      if (state.loggedInUser) {
        if (!state.loggedInUser.first_name && !state.loggedInUser.last_name) {
          return state.loggedInUser.email;
        }
        return (
          state.loggedInUser.first_name + " " + state.loggedInUser.last_name
        );
      }
      return "N/A";
    },
  },
};
